import Keycloak from "keycloak-js";

function getBaseUrl() {
  const url = window.location.origin;

  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test" ||
    url.includes("localhost")
  ) {
    return `http://localhost:8080`;
  } else if (url.includes("live.")) {
    return url.replace("live", "idp.smartcityhub");
  } else {
    return url.replace("treelights", "idp.smartcityhub");
  }
}

const keycloak = new Keycloak({
  url: getBaseUrl(),
  realm: "smartcityhub",
  clientId: "tree-lights-application",
});

export default keycloak;
