import React, {useEffect, useRef, useState} from "react";
import L from "leaflet";
import "./AddTreeModal.css";
import arrowLeft from "../../images/arrowLeft.svg";
import trashCan from "../../images/trashCan.svg";
import xCircle from "../../images/xCircle.svg";
import linkedIcon from "../../images/linked.svg";
import {InputField} from "../../reusables/basic/InputField";
import {LabeledButton} from "../../reusables/basic/LabeledButton";
import {LocationSelector} from "../../reusables/compound/LocationSelector";
import {AddTreeConfirmationModal} from "./AddTreeConfirmationModal";
import {FloatingButton} from "../../reusables/basic/FloatingButton";
import {Popup} from "../../reusables/compound/Popup";
import {
  SensorData,
  SoilData,
  SpeciesData,
  TreeData,
} from "../../logic/APITypes";
import {executeSearch, SearchTreeNode} from "../../logic/SearchSuggestionsList";
import API from "../../logic/API";

type AddTreeModalProps = {
  closeModal: () => void;
  goBack: () => void;
  speciesData: Record<number, SpeciesData>;
  speciesAutocomplete: {
    suggestions: {[key: string]: string};
    tree: SearchTreeNode;
  };
  sensorData: Record<number, SensorData>;
  sensorAutocomplete: {
    suggestions: {[key: string]: string};
    tree: SearchTreeNode;
  };
  soilData: Record<number, SoilData>;
  soilAutocomplete: {
    suggestions: {[key: string]: string};
    tree: SearchTreeNode;
  };
  addOrEditTree: (tree: TreeData) => void;
  useEditMode: boolean;
  initialInfo?: {tree: TreeData; entryIndex: number};
  deleteTree?: (tree: TreeData, entryIndex: number) => void;
  user: {logOut: () => void; isAdmin: boolean};
};

/**
 * Displays an overlay with a form to enter data for adding a new tree.
 *
 * @component
 * @props closeModal: A function to call when the "close modal"-button is clicked
 * @props goBack: A function to call when the "go back"-button is clicked
 * @props speciesData: A dictionary mapping ids to species
 * @props speciesAutocomplete: A list of valid tree species to be suggested – contains a map of ID to suggestion text and the search tree root
 * @props sensorData: A dictionary mapping ids to sensors
 * @props sensorAutocomplete: A list of valid sensor names to be suggested – contains a map of ID to suggestion text and the search tree root
 * @props soilData: A dictionary mapping ids to types of soil compositions
 * @props soilAutocomplete: A list of valid types of soil compositions to be suggested – contains a map of ID to suggestion text and the search tree root
 * @props addOrEditTree: A function to call with the current tree info when the tree creation/editing was successful
 * @props useEditMode: Whether the modal is used to edit an existing tree instead of creating a new one
 * @props initialInfo?: Information about the tree being edited (tree) and from which entry (not the displayed row) of the table data the modal was opened (entryIndex)
 * @props deleteTree?: A function to call with the current tree info and entry number when the tree was successfully deleted via the API
 * @props user: An object to manage the user – contains the status of whether they're an admin and a function to log them out
 */
const AddTreeModal = (props: AddTreeModalProps) => {
  const idPrefix = props.useEditMode ? "edit" : "add";
  const treeNumberRef = useRef("");
  const waitingForTreeNumberCheckRef = useRef({
    checkId: 0,
    resolved: true,
  });
  const getUsedSensorIds = () => {
    const newUsedSensorIds: {[key: string]: true} = {};
    for (const sensorId in props.sensorData)
      if (
        props.sensorData[sensorId].tree &&
        props.sensorData[sensorId].tree?.id != props.initialInfo?.tree.id
      )
        newUsedSensorIds[sensorId] = true;
    return newUsedSensorIds;
  };
  const [deleting, setDeleting] = useState(false);
  const [unlinking, setUnlinking] = useState(false);
  const [popupWaiting, setPopupWaiting] = useState(false);
  const [editWaiting, setEditWaiting] = useState(false);
  const [species, setSpecies] = useState(
    props.initialInfo
      ? props.initialInfo.tree.species.species_latin +
          " (" +
          props.initialInfo.tree.species.species_german +
          ")"
      : ""
  );
  const [speciesError, setSpeciesError] = useState<string | undefined>(
    undefined
  );
  const [speciesId, setSpeciesId] = useState(
    props.initialInfo ? props.initialInfo.tree.species.id : -1
  );
  const [treeNumber, setTreeNumber] = useState(
    props.initialInfo ? props.initialInfo.tree.tree_number : ""
  );
  const [treeNumberError, setTreeNumberError] = useState<string | undefined>(
    undefined
  );
  const [initialSensor, setInitialSensor] = useState(
    props.initialInfo ? props.initialInfo.tree.sensor : null
  );
  const [sensorName, setSensorName] = useState(
    props.initialInfo?.tree.sensor
      ? props.sensorData[props.initialInfo.tree.sensor.id].name !== null
        ? props.sensorData[props.initialInfo.tree.sensor.id].name +
          " (" +
          props.sensorData[props.initialInfo.tree.sensor.id].e_uid +
          ")"
        : props.sensorData[props.initialInfo.tree.sensor.id].e_uid
      : ""
  );
  const [sensorId, setSensorId] = useState(
    props.initialInfo?.tree.sensor ? props.initialInfo.tree.sensor.id : -1
  );
  const [sensorError, setSensorError] = useState<string | undefined>(undefined);
  const [sensorEditable, setSensorEditable] = useState(
    props.initialInfo === undefined || props.initialInfo.tree.sensor === null
  );
  const [waitingForTreeNumberCheck, setWaitingForTreeNumberCheck] = useState({
    checkId: 0,
    resolved: true,
  });
  const [soil, setSoil] = useState(
    props.initialInfo?.tree.soil_composition
      ? props.initialInfo.tree.soil_composition.name
      : ""
  );
  const [soilError, setSoilError] = useState<string | undefined>(undefined);
  const [soilId, setSoilId] = useState(
    props.initialInfo?.tree.soil_composition
      ? props.initialInfo.tree.soil_composition.id
      : -1
  );
  const [location, setLocation] = useState<L.LatLng | null>(
    props.initialInfo
      ? L.latLng(props.initialInfo.tree.lat, props.initialInfo.tree.long)
      : null
  );
  const [showingSummary, setShowingSummary] = useState(false);
  const [usedSensorIds, setUsedSensorIds] = useState<{[key: string]: true}>(
    getUsedSensorIds()
  );

  useEffect(() => {
    setUsedSensorIds(getUsedSensorIds());
  }, [props.sensorData]);

  useEffect(() => {
    treeNumberRef.current = treeNumber;
  }, [treeNumber]);
  useEffect(() => {
    waitingForTreeNumberCheckRef.current = waitingForTreeNumberCheck;
  }, [waitingForTreeNumberCheck]);

  const checkTreeSpecies = () => {
    if (species.trim().length === 0)
      setSpeciesError(
        "Baumart ist ein Pflichtfeld. Bitte wählen Sie die Baumart aus."
      );
    else if (speciesId === -1)
      setSpeciesError(
        "Baumart ist nicht bekannt. Bitte korrigieren Sie Ihre Eingabe."
      );
    else setSpeciesError(undefined);
  };

  const checkSensor = () => {
    if (sensorId === -1 && sensorName !== "")
      setSensorError(
        "Sensor ist nicht bekannt. Bitte korrigieren Sie Ihre Eingabe."
      );
    else if (sensorId === -2)
      setSensorError(
        "Sensor ist bereits mit einem anderen Baum gekoppelt. Bitte korrigieren Sie Ihre Eingabe."
      );
    else setSensorError(undefined);
  };

  const wasSensorEdited = () => {
    if (!props.initialInfo || sensorName.length === 0) return false;
    if (!initialSensor && sensorName.length > 0) return true;
    if (!initialSensor) return false;
    const sensor = props.sensorData[initialSensor.id];
    return (
      sensorName !==
      (sensor.name && sensor.name !== ""
        ? sensor.name + " (" + sensor.e_uid + ")"
        : sensor.e_uid)
    );
  };

  const wasSoilEdited = () => {
    if (!props.initialInfo) return false;
    if (!props.initialInfo.tree.soil_composition && soil.length > 0)
      return true;
    if (!props.initialInfo.tree.soil_composition) return false;
    return soil !== props.initialInfo.tree.soil_composition.name;
  };

  const checkTreeNumber = (checkId: number) => {
    if (treeNumberRef.current.trim().length === 0)
      setTreeNumberError(
        "Baumnummer ist ein Pflichtfeld. Bitte ergänzen Sie die Baumnummer."
      );
    else setTreeNumberError(undefined);
    if (
      treeNumberRef.current.length !== 0 &&
      (!props.initialInfo ||
        props.initialInfo.tree.tree_number !== treeNumberRef.current)
    ) {
      API.getTreeByTreeNumber(treeNumberRef.current).then((response) => {
        if (response.success)
          setTreeNumberError(
            "Baumnummer ist bereits im System hinterlegt. Bitte korrigieren Sie Ihre Eingabe."
          );
        if (waitingForTreeNumberCheckRef.current.checkId === checkId)
          setWaitingForTreeNumberCheck({
            checkId: checkId,
            resolved: true,
          });
      });
    }
  };

  const checkSoil = () => {
    if (soil.trim().length === 0)
      setSoilError(
        "Bodenart ist ein Pflichtfeld. Bitte wählen Sie die Bodenart aus."
      );
    else if (soilId === -1)
      setSoilError(
        "Bodenart ist nicht bekannt. Bitte korrigieren Sie Ihre Eingabe."
      );
    else setSoilError(undefined);
  };

  return (
    <div className="addTreeModal" id={idPrefix + "TreeModal"}>
      {deleting ? (
        <Popup
          title={"Baum löschen"}
          text={"Der Baum wird unwiderruflich gelöscht."}
          options={[
            {
              id: "editTreeModalDeleteTreePopupCancelButton",
              label: "Abbrechen",
              importance: "tertiary",
              onClick: () => {
                setDeleting(false);
              },
              disabled: popupWaiting,
            },
            {
              id: "editTreeModalDeleteTreePopupDeleteButton",
              label: "Löschen",
              importance: "primary",
              onClick: () => {
                if (props.initialInfo?.tree.id) {
                  setPopupWaiting(true);
                  API.deleteTree(props.initialInfo.tree.id).then((res) => {
                    if (
                      res.success &&
                      props.initialInfo?.tree.id &&
                      props.deleteTree
                    ) {
                      props.deleteTree(
                        props.initialInfo.tree,
                        props.initialInfo.entryIndex
                      );
                      props.goBack();
                    } else if (res.httpCode === 403) {
                      setDeleting(false);
                      props.user.logOut();
                    }
                    setPopupWaiting(false);
                  });
                }
              },
              disabled: popupWaiting,
            },
          ]}
          onBackgroundClick={() => {
            setDeleting(false);
          }}
        />
      ) : null}
      {unlinking ? (
        <Popup
          title={"Sensor entkoppeln"}
          text={
            "Der Sensor wird entkoppelt. Die bisher gesammelten Daten gehen dabei nicht verloren."
          }
          options={[
            {
              id: "editTreeModalUnlinkSensorPopupCancelButton",
              label: "Abbrechen",
              importance: "tertiary",
              onClick: () => {
                setUnlinking(false);
              },
              disabled: popupWaiting,
            },
            {
              id: "editTreeModalUnlinkSensorPopupUnlinkButton",
              label: "Entkoppeln",
              importance: "primary",
              onClick: () => {
                if (props.initialInfo?.tree.id) {
                  setPopupWaiting(true);
                  setSensorError(undefined);
                  setSensorName("");
                  setSensorId(-1);
                  API.unlinkSensorFromTree(props.initialInfo.tree.id).then(
                    (res) => {
                      if (res.success && props.initialInfo) {
                        props.addOrEditTree({
                          ...props.initialInfo.tree,
                          sensor: null,
                          ground_moisture_level: null,
                        });
                        setInitialSensor(null);
                        setSensorEditable(true);
                        setUnlinking(false);
                      } else if (res.httpCode === 403) props.user.logOut();
                      setPopupWaiting(false);
                    }
                  );
                }
              },
              disabled: popupWaiting,
            },
          ]}
          onBackgroundClick={() => {
            setDeleting(false);
          }}
        />
      ) : null}
      {showingSummary && location !== null ? (
        <AddTreeConfirmationModal
          location={location}
          gerSpecies={props.speciesData[speciesId].species_german}
          latSpecies={props.speciesData[speciesId].species_latin}
          treeNumber={treeNumber}
          speciesId={speciesId}
          sensor={
            sensorId === -1
              ? undefined
              : {id: sensorId, eUid: props.sensorData[sensorId].e_uid}
          }
          soil={soilId === -1 ? undefined : props.soilData[soilId]}
          goBack={() => setShowingSummary(false)}
          onTreeAdded={(id) => {
            props.addOrEditTree({
              id: id,
              tree_number: treeNumber,
              species: {
                species_german: props.speciesData[speciesId].species_german,
                species_latin: props.speciesData[speciesId].species_latin,
                id: speciesId,
              },
              lat: location.lat,
              long: location.lng,
              ground_moisture_level: null,
              sensor: sensorId === -1 ? null : {id: sensorId},
              soil_composition: soilId === -1 ? null : props.soilData[soilId],
            });
            props.goBack();
          }}
          user={props.user}
        />
      ) : null}
      <div className="closeButtonContainer">
        <FloatingButton
          id={idPrefix + "TreeModalCloseButton"}
          image={xCircle}
          onClick={props.closeModal}
          disabled={editWaiting}
        />
      </div>
      <div hidden={showingSummary}>
        <button
          onClick={props.goBack}
          className="leaveAddTree"
          id={idPrefix + "TreeModalGoBackButton"}
          disabled={editWaiting}
        >
          <img src={arrowLeft} />
        </button>
        <div className="addTreeTitle">
          {props.useEditMode
            ? props.initialInfo?.tree.tree_number
            : "Baum hinzufügen"}
          {props.useEditMode ? (
            <button
              onClick={() => {
                setPopupWaiting(false);
                setDeleting(true);
              }}
              className="deleteTree"
              id={idPrefix + "TreeModalDeleteTreeButton"}
              disabled={editWaiting}
            >
              <img src={trashCan} />
            </button>
          ) : null}
        </div>
        <div className="addTreeInput">
          <InputField
            label={"Baumart"}
            id={idPrefix + "TreeModalSpeciesTextInput"}
            value={species}
            errorText={speciesError}
            onBlur={checkTreeSpecies}
            edited={
              props.initialInfo &&
              species !==
                props.initialInfo.tree.species.species_latin +
                  " (" +
                  props.initialInfo.tree.species.species_german +
                  ")"
            }
            setValue={(v) => {
              setSpecies(v);
              const hits = executeSearch(
                v.toString().trim(),
                props.speciesAutocomplete.tree,
                true
              );
              let hitId = "-1";
              for (const h of hits) {
                if (
                  h.startIndex === 0 &&
                  props.speciesAutocomplete.suggestions[h.id].length ===
                    v.toString().trim().length
                ) {
                  hitId = h.id;
                  break;
                }
              }
              setSpeciesId(+hitId);
            }}
            autocomplete={props.speciesAutocomplete}
            disabled={editWaiting}
          />
          <InputField
            label={"Baumnummer"}
            id={idPrefix + "TreeModalTreeNumberTextInput"}
            value={treeNumber}
            afterInactivity={() => {
              checkTreeNumber(waitingForTreeNumberCheckRef.current.checkId + 1);
              setWaitingForTreeNumberCheck({
                checkId: waitingForTreeNumberCheckRef.current.checkId + 1,
                resolved: false,
              });
            }}
            onBlur={() => {
              if (treeNumberRef.current.trim().length === 0)
                setTreeNumberError(
                  "Baumnummer ist ein Pflichtfeld. Bitte ergänzen Sie die Baumnummer."
                );
            }}
            setValue={(v) => {
              setWaitingForTreeNumberCheck({
                checkId: waitingForTreeNumberCheck.checkId + 1,
                resolved: false,
              });
              setTreeNumber(v);
            }}
            optionalLabel="AE-123456"
            edited={
              props.initialInfo &&
              treeNumber !== props.initialInfo.tree.tree_number
            }
            errorText={treeNumberError}
            disabled={editWaiting}
          />
          <div className="addTreeInputRowContainer">
            <div id="addTreeInputSensor">
              <InputField
                label={"Sensor"}
                optionalLabel={"(optional)"}
                id={idPrefix + "TreeModalSensorNameTextInput"}
                value={sensorName}
                errorText={sensorError}
                onBlur={checkSensor}
                edited={wasSensorEdited()}
                setValue={(v) => {
                  setSensorName(v);
                  const hits = executeSearch(
                    v.toString().trim(),
                    props.sensorAutocomplete.tree,
                    true
                  );
                  let hitId = "-1";
                  for (const h of hits) {
                    if (
                      h.startIndex === 0 &&
                      props.sensorAutocomplete.suggestions[h.id].length ===
                        v.toString().trim().length
                    ) {
                      hitId = h.id;
                      break;
                    }
                  }
                  if (usedSensorIds[hitId]) hitId = "-2";
                  setSensorId(+hitId);
                }}
                autocomplete={{
                  ...props.sensorAutocomplete,
                  ignoredIds: usedSensorIds,
                }}
                disabled={editWaiting || !sensorEditable}
                labelButton={{
                  id: idPrefix + "TreeModalSensorNameButton",
                  image: linkedIcon,
                  onClick: () => {
                    setUnlinking(true);
                  },
                  ignoreRounding: true,
                  greyOut: true,
                  disabled: editWaiting || sensorEditable,
                  withoutBackground: true,
                }}
              />
            </div>
            <div id="addTreeInputSoil">
              <InputField
                label={"Bodenart"}
                id={idPrefix + "TreeModalSoilTextInput"}
                value={soil}
                errorText={soilError}
                onBlur={checkSoil}
                edited={wasSoilEdited()}
                setValue={(v) => {
                  setSoil(v);
                  const hits = executeSearch(
                    v.toString().trim(),
                    props.soilAutocomplete.tree,
                    true
                  );
                  let hitId = "-1";
                  for (const h of hits) {
                    if (
                      h.startIndex === 0 &&
                      props.soilAutocomplete.suggestions[h.id].length ===
                        v.toString().trim().length
                    ) {
                      hitId = h.id;
                      break;
                    }
                  }
                  setSoilId(+hitId);
                }}
                autocomplete={props.soilAutocomplete}
                disabled={editWaiting}
              />
            </div>
          </div>
          <div className="addTreeButton">
            <LabeledButton
              label={props.useEditMode ? "Speichern" : "Weiter"}
              id={idPrefix + "TreeModalProceedButton"}
              importance="primary"
              disabled={
                editWaiting ||
                (props.useEditMode &&
                  props.initialInfo &&
                  location &&
                  treeNumber === props.initialInfo.tree.tree_number &&
                  species ===
                    props.initialInfo.tree.species.species_latin +
                      " (" +
                      props.initialInfo.tree.species.species_german +
                      ")" &&
                  props.initialInfo.tree.lat === location.lat &&
                  props.initialInfo.tree.long === location.lng &&
                  (sensorId === -1 ||
                    (initialSensor && initialSensor.id === sensorId)) &&
                  ((soilId === -1 &&
                    !props.initialInfo.tree.soil_composition) ||
                    props.initialInfo.tree.soil_composition?.id === soilId)) ||
                !waitingForTreeNumberCheck.resolved ||
                sensorError !== undefined ||
                speciesError !== undefined ||
                treeNumberError !== undefined ||
                soilError !== undefined ||
                speciesId === -1 ||
                treeNumber.length === 0 ||
                soilId === -1 ||
                location === null ||
                location.lat === 0 ||
                location.lng === 0
              }
              onClick={() => {
                if (
                  props.useEditMode &&
                  props.initialInfo &&
                  props.initialInfo.tree.id !== null &&
                  location
                ) {
                  setEditWaiting(true);
                  const changes: {
                    tree_number?: string;
                    species?: number;
                    lat?: number;
                    lng?: number;
                    soil_composition?: number | null;
                    sensor?: number | null;
                  } = {};
                  if (treeNumber !== props.initialInfo.tree.tree_number)
                    changes.tree_number = treeNumber;
                  if (speciesId !== props.initialInfo.tree.species.id)
                    changes.species = speciesId;
                  if (location && location.lat !== props.initialInfo?.tree.lat)
                    changes.lat = location.lat;
                  if (location && location.lng !== props.initialInfo?.tree.long)
                    changes.lng = location.lng;
                  if (sensorId !== -1 && sensorId !== initialSensor?.id)
                    changes.sensor = sensorId;
                  if (soilId === -1 && props.initialInfo.tree.soil_composition)
                    changes.soil_composition = null;
                  else if (
                    soilId !== -1 &&
                    soilId !== props.initialInfo?.tree.soil_composition?.id
                  )
                    changes.soil_composition = soilId;
                  API.editTree(props.initialInfo.tree.id, changes).then(
                    (res) => {
                      if (res.success && props.initialInfo?.tree.id) {
                        props.addOrEditTree({
                          id: props.initialInfo.tree.id,
                          tree_number: treeNumber,
                          lat: location.lat,
                          long: location.lng,
                          ground_moisture_level:
                            props.initialInfo.tree.ground_moisture_level,
                          species: props.speciesData[speciesId],
                          sensor: sensorId === -1 ? null : {id: sensorId},
                          soil_composition:
                            soilId === -1 ? null : props.soilData[soilId],
                        });
                        props.goBack();
                      } else if (res.httpCode === 403) props.user.logOut();
                      setEditWaiting(false);
                    }
                  );
                } else setShowingSummary(true);
              }}
            />
          </div>
        </div>
        <div className="locationSelector">
          <LocationSelector
            idBase={idPrefix + "TreeModal"}
            initialLocation={
              props.initialInfo
                ? L.latLng(
                    props.initialInfo.tree.lat,
                    props.initialInfo.tree.long
                  )
                : L.latLng(49.79, 9.95)
            }
            location={location}
            setLocation={setLocation}
            edited={
              props.initialInfo &&
              (location?.lat !== props.initialInfo.tree.lat ||
                location?.lng !== props.initialInfo.tree.long)
            }
            disabled={editWaiting}
          />
        </div>
      </div>
    </div>
  );
};

export {AddTreeModal};
