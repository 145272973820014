import React from "react";
import L from "leaflet";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./LocationPresenter.css";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import icon from "../../images/marker0.svg";

type locationPresenterProps = {
  idBase: string;
  location: L.LatLng;
};

/**
 * A map embed to select a location with updating inputs for latitude  and longitude.
 *
 * @component
 * @props idBase: A prefix for the HTML-IDs of elements in the presenter. Should reflect the path of entrypoints
 * @props location: The Lat/Lng to be marked and centered on
 */
const LocationPresenter = (props: locationPresenterProps) => {
  L.Marker.prototype.options.icon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [37, 53],
    iconAnchor: [19, 53],
    popupAnchor: [0, -45],
  });
  return (
    <div className={"locationPresenterPanel"}>
      <div className="locationPresenterMapPanel">
        <MapContainer
          center={props.location}
          zoom={13}
          scrollWheelZoom={false}
          zoomControl={false}
          dragging={false}
          doubleClickZoom={false}
        >
          <TileLayer
            attribution="&copy; Datenquellen: <a href='https://vermessung.bayern.de/file/pdf/7203/Nutzungsbedingungen_Viewing.pdf' target='_blank' rel='noopener noreferrer'>Bayerische Vermessungs&shy;verwaltung</a>, GeoBasis-DE / <a href='https://www.bkg.bund.de/DE/Home/home.html' target='_blank' rel='noopener noreferrer'>BKG</a> 2023 – Daten verändert"
            url={
              (process.env.NODE_ENV === "development" ||
              process.env.NODE_ENV === "test"
                ? "http://localhost"
                : "") + "/map{s}/by_webkarte/smerc/{z}/{x}/{y}"
            }
            subdomains="123456789"
          />
          <Marker position={props.location}></Marker>
        </MapContainer>
      </div>
      <div className="locationPresenterTextBar">
        <div className="presenterTextBarElement">
          <div className="presenterLatLongLabel">Lat:</div>
          <div className="presenterLatLongValue">{props.location.lat}</div>
        </div>
        <div className="presenterTextBarElement">
          <div className="presenterLatLongLabel">Lng:</div>
          <div className="presenterLatLongValue">{props.location.lng}</div>
        </div>
      </div>
    </div>
  );
};

export {LocationPresenter};
