import React, {useState, useEffect} from "react";
import "./App.css";
import API from "../logic/API";
import {SensorData, SpeciesData, TreeData, SoilData} from "../logic/APITypes";
import objectListToDictionary from "../logic/ObjectListToDictionary";
import {buildSearchTree, SearchTreeNode} from "../logic/SearchSuggestionsList";
import {ListModal} from "./modal/ListModal";
import {NavigationBar, navigationTabOptions} from "./modal/NavigationBar";
import {MapPanel} from "./map/MapPanel";
import {Footer} from "./modal/Footer";
import {LoginModal} from "./modal/LoginModal";
import {useKeycloak} from "@react-keycloak/web";
import {Popup, popupProps} from "../reusables/compound/Popup";
import TreeMarkerPopup from "./map/TreeMarkerPopup";

function App(props: {keycloakInitError: boolean}) {
  const {keycloak, initialized} =
    process.env.NODE_ENV === "test"
      ? {
          keycloak: {
            authenticated: false,
            onReady: () => null,
            hasResourceRole: () => false,
          },
          initialized: true,
        }
      : useKeycloak();
  const [selectedNavigationTab, setSelectedNavigationTab] =
    useState<navigationTabOptions>("Map");
  const [showLoginWindow, setShowLoginWindow] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [treeData, setTreeData] = useState<Record<number, TreeData>>([]);
  const [speciesData, setSpeciesData] = useState<Record<number, SpeciesData>>(
    []
  );
  const [sensorData, setSensorData] = useState<Record<number, SensorData>>([]);
  const [soilData, setSoilData] = useState<Record<number, SoilData>>([]);
  const [speciesAutocomplete, setSpeciesAutocomplete] = useState<{
    suggestions: {[id: string]: string};
    tree: SearchTreeNode;
  }>();
  const [sensorAutocomplete, setSensorAutocomplete] = useState<{
    suggestions: {[id: string]: string};
    tree: SearchTreeNode;
  }>();
  const [soilAutocomplete, setSoilAutocomplete] = useState<{
    suggestions: {[id: string]: string};
    tree: SearchTreeNode;
  }>();
  const [popupContent, setPopupContent] = useState<popupProps>({
    title: "",
    text: "",
    options: [],
  });
  const [showPopup, setShowPopup] = useState(false);
  const [openedTree, setOpenedTree] = useState<number | null>(null);

  const addNewTree = (tree: TreeData) => {
    const newTreeData = {...treeData};
    newTreeData[tree.id] = tree;
    setTreeData(newTreeData);
    if (tree.sensor) {
      const newSensorData = {...sensorData};
      newSensorData[tree.sensor.id].tree = {
        id: tree.id,
        tree_number: tree.tree_number,
      };
      setSensorData(newSensorData);
    }
  };
  const editTree = (tree: TreeData) => {
    if (tree.sensor !== treeData[tree.id].sensor) {
      const newSensorData = {...sensorData};
      const oldSensor = treeData[tree.id].sensor;
      if (oldSensor) newSensorData[oldSensor.id].tree = undefined;
      if (tree.sensor)
        newSensorData[tree.sensor.id].tree = {
          id: tree.id,
          tree_number: tree.tree_number,
        };
      setSensorData(newSensorData);
    }
    const newTreeData = {...treeData};
    newTreeData[tree.id] = tree;
    setTreeData(newTreeData);
  };
  const deleteTree = (id: number) => {
    const newTreeData = {...treeData};
    delete newTreeData[id];
    setTreeData(newTreeData);
    if (treeData[id].sensor) {
      const newSensorData = {...sensorData};
      const oldSensor = treeData[id].sensor;
      if (oldSensor) newSensorData[oldSensor.id].tree = undefined;
      setSensorData(newSensorData);
    }
  };
  const renameSensor = (id: number, name: string) => {
    const newSensorData = {...sensorData};
    newSensorData[id].name = name;
    setSensorData(newSensorData);
    const newTreeData = {...treeData};
    if (sensorData[id].tree)
      newTreeData[id].sensor = {id: id, name: sensorData[id].name || undefined};
    setTreeData(newTreeData);
    const newSuggestions = {...sensorAutocomplete?.suggestions};
    newSuggestions[id] =
      name && name !== ""
        ? name + " (" + sensorData[id].e_uid + ")"
        : sensorData[id].e_uid;
    setSensorAutocomplete({
      suggestions: newSuggestions,
      tree: buildSearchTree(newSuggestions, true),
    });
  };
  const logOut = () => {
    setIsAdmin(false);
    setIsLoggedIn(false);
    setSelectedNavigationTab("Map");
    setShowLoginWindow(true);
  };
  const loadSpeciesAndSoils = () => {
    API.getSpecies().then((res) => {
      if (res.payload) {
        setSpeciesData(objectListToDictionary(res.payload, "id"));
        const suggestions = objectListToDictionary<SpeciesData, string>(
          res.payload,
          "id",
          (s) => s.species_latin + " (" + s.species_german + ")"
        );
        setSpeciesAutocomplete({
          suggestions: suggestions,
          tree: buildSearchTree(suggestions, true),
        });
      }
    });
    API.getSoils().then((res) => {
      if (res.payload) {
        setSoilData(objectListToDictionary(res.payload, "id"));
        const suggestions = objectListToDictionary<SoilData, string>(
          res.payload,
          "id",
          (s) => s.name
        );
        setSoilAutocomplete({
          suggestions: suggestions,
          tree: buildSearchTree(suggestions, true),
        });
      }
    });
  };

  const updateLoggedIn = () => {
    if (process.env.NODE_ENV !== "test" && keycloak.authenticated) {
      if (keycloak.hasResourceRole("SCH Baumampel Administration"))
        setIsAdmin(true);
      setIsLoggedIn(true);
      loadSpeciesAndSoils();
    }
    const apiCalls = async () => {
      API.getTrees().then((res) => {
        if (res.payload) setTreeData(objectListToDictionary(res.payload, "id"));
        API.getSensors().then((res) => {
          if (res.payload) {
            setSensorData(objectListToDictionary(res.payload, "id"));
            const suggestions = objectListToDictionary<SensorData, string>(
              res.payload,
              "id",
              (s) =>
                s.name && s.name !== ""
                  ? s.name + " (" + s.e_uid + ")"
                  : s.e_uid
            );
            setSensorAutocomplete({
              suggestions: suggestions,
              tree: buildSearchTree(suggestions, true),
            });
          }
        });
      });
    };
    apiCalls();
  };

  const ticketAlreadyExistsPopup: popupProps = {
    title: "Kein Auftrag nötig",
    text: "Es wurde kein Gießauftrag erstellt, da es für diesen Baum bereits einen ausstehenden Auftrag gibt. Das Gartenamt kümmert sich darum.",
    options: [
      {
        id: "requestWateringNoActionButton",
        label: "OK",
        importance: "primary",
        onClick: () => {
          setShowPopup(false);
        },
      },
    ],
  };

  const requestWatering = (treeId: number) => {
    setPopupContent({
      title: "Gießauftrag wird erstellt",
      text: "Ein Gießauftrag wird angelegt, bitte warten Sie einen Moment…",
      options: [],
    });
    API.sendWateringCommand(treeId).then((wateringCommand) => {
      if (wateringCommand.httpCode === 200)
        setPopupContent(ticketAlreadyExistsPopup);
      else if (wateringCommand.httpCode === 201)
        setPopupContent({
          title: "Gießauftrag erstellt",
          text: "Ein Gießauftrag fürs Gartenamt wurde angelegt.",
          options: [
            {
              id: "requestWateringSuccessButton",
              label: "Weiter",
              importance: "primary",
              onClick: () => {
                setShowPopup(false);
              },
            },
          ],
        });
      else
        setPopupContent({
          title: "Erstellung fehlgeschlagen",
          text: `Die Erstellung des Gießauftrags ist fehlgeschlagen. (Fehlercode ${wateringCommand.httpCode})`,
          options: [
            {
              id: "requestWateringRetryButton",
              label: "Nochmal",
              importance: "primary",
              onClick: () => {
                requestWatering(treeId);
              },
            },
            {
              id: "requestWateringFailureButton",
              label: "Abbrechen",
              importance: "tertiary",
              onClick: () => {
                setShowPopup(false);
              },
            },
          ],
        });
    });
  };

  const probeWatering = (treeId: number) => {
    if (
      treeData[treeId].ticket?.status === "open" ||
      treeData[treeId].ticket?.status === "new"
    )
      setPopupContent(ticketAlreadyExistsPopup);
    else {
      setPopupContent({
        title: "Gießauftrag erstellen?",
        text: `Möchten Sie einen Gießauftrag für den Baum "${treeData[treeId].tree_number}" erstellen? Das Würzburger Gartenamt kümmert sich dann darum.`,
        options: [
          {
            id: "requestWateringConfirmButton",
            label: "Weiter",
            importance: "primary",
            onClick: () => requestWatering(treeId),
          },
          {
            id: "requestWateringCancelButton",
            label: "Abbrechen",
            importance: "tertiary",
            onClick: () => {
              setShowPopup(false);
            },
          },
        ],
      });
    }
    setShowPopup(true);
  };

  useEffect(() => {
    if (initialized || props.keycloakInitError) updateLoggedIn();
  }, [initialized, props.keycloakInitError]);

  if (!initialized && !props.keycloakInitError) return null;

  return (
    <>
      {showLoginWindow ? (
        <LoginModal
          hideLoginWindow={() => setShowLoginWindow(false)}
          isLoggedIn={isLoggedIn}
        />
      ) : null}
      <NavigationBar
        selectedNavigationTab={selectedNavigationTab}
        setSelectedNavigationTab={setSelectedNavigationTab}
        isLoggedIn={isLoggedIn}
        showLoginWindow={() => {
          setShowLoginWindow(true);
        }}
        treeData={treeData}
        probeWatering={probeWatering}
        closeModals={() => {
          setOpenedTree(null);
          setSelectedNavigationTab("Map");
        }}
      />
      <MapPanel
        treeData={treeData}
        probeWatering={probeWatering}
        isAdmin={isAdmin}
        openedTree={{get: openedTree, set: setOpenedTree}}
      />
      {selectedNavigationTab !== "Map" ? (
        <div className="blurMapOverlay" />
      ) : null}
      {selectedNavigationTab === "List" &&
      sensorAutocomplete &&
      (!isAdmin || (speciesAutocomplete && soilAutocomplete)) ? (
        <ListModal
          closeModal={() => setSelectedNavigationTab("Map")}
          treeData={treeData}
          speciesData={speciesData}
          speciesAutocomplete={speciesAutocomplete}
          soilData={soilData}
          soilAutocomplete={soilAutocomplete}
          addNewTree={addNewTree}
          editTree={editTree}
          deleteTree={deleteTree}
          renameSensor={renameSensor}
          sensor={{
            data: sensorData,
            setData: setSensorData,
            autocomplete: sensorAutocomplete,
          }}
          probeWatering={probeWatering}
          user={{isAdmin: isAdmin, logOut: logOut}}
        />
      ) : null}

      {openedTree ? (
        <TreeMarkerPopup
          tree={treeData[openedTree]}
          probeWatering={probeWatering}
          isAdmin={isAdmin}
          closeMarker={() => setOpenedTree(null)}
          openedTree={{
            get: openedTree,
            set: setOpenedTree,
          }}
        />
      ) : null}
      {showPopup ? (
        <Popup
          title={popupContent?.title}
          text={popupContent.text}
          options={popupContent.options}
        />
      ) : null}
      <Footer />
    </>
  );
}

export default App;
