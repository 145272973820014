import React, {useState} from "react";
import L from "leaflet";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./LocationSelector.css";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import icon from "../../images/marker0.svg";
import {MapClickListener} from "../map/MapClickListener";
import {MapControls} from "../map/MapControls";
import {MapNavigator} from "../map/MapNavigator";

type locationSelectorProps = {
  idBase: string;
  initialLocation: L.LatLng;
  location: L.LatLng | null;
  setLocation: (position: L.LatLng) => void;
  edited?: boolean;
  disabled?: boolean;
};

/**
 * A map embed to select a location with updating inputs for latitude  and longitude.
 *
 * @component
 * @props idBase: A prefix for the HTML-IDs of elements in the selector. Should reflect the path of entrypoints
 * @props initialLocation: The Lat/Lng to centered on
 * @props location: The currently selected Lat/Lng – can be implemented as a useState()-pair with setLocation
 * @props setLocation: How to update the currently selected Lat/Lng – can be implemented as a useState()-pair with location
 * @props edited?: Whether the location has been changed and thus italic text is to be used
 * @props disabled?: Whether elements within the component can be interacted with
 */
const LocationSelector = (props: locationSelectorProps) => {
  L.Marker.prototype.options.icon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [37, 53],
    iconAnchor: [19, 53],
    popupAnchor: [0, -45],
  });
  const [latLngText, setLatLngText] = useState(
    props.location
      ? {lat: "" + props.location.lat, lng: "" + props.location.lng}
      : {lat: "", lng: ""}
  );
  const [ignoreClick, setIgnoreClick] = useState(false);
  const [flyDestination, setFlyDestination] = useState<{
    dest: L.LatLng;
    fly: boolean;
    ignoreMaxDistance?: boolean;
  }>({
    dest: props.initialLocation,
    fly: false,
  });
  const markerChange = (newPos: L.LatLng) => {
    props.setLocation(newPos);
    setLatLngText({lat: "" + newPos.lat, lng: "" + newPos.lng});
  };
  const latLngTextChange = (value: string, wasLat: boolean) => {
    const lat = wasLat ? value : latLngText.lat;
    const lng = wasLat ? latLngText.lng : value;
    setLatLngText({lat: lat, lng: lng});
    if (Math.abs(+lat) <= 90 && Math.abs(+lng) <= 180) {
      props.setLocation(L.latLng(+lat, +lng));
      setFlyDestination({dest: L.latLng(+lat, +lng), fly: true});
    }
  };
  return (
    <div className={"locationSelectorPanel"}>
      <div className="locationSelectorMapPanel">
        <MapContainer
          center={props.initialLocation}
          zoom={15}
          scrollWheelZoom={false}
          zoomControl={false}
        >
          <TileLayer
            attribution="&copy; Datenquellen: <a href='https://vermessung.bayern.de/file/pdf/7203/Nutzungsbedingungen_Viewing.pdf' target='_blank' rel='noopener noreferrer'>Bayerische Vermessungs&shy;verwaltung</a>, GeoBasis-DE / <a href='https://www.bkg.bund.de/DE/Home/home.html' target='_blank' rel='noopener noreferrer'>BKG</a> 2023 – Daten verändert"
            url={
              (process.env.NODE_ENV === "development" ||
              process.env.NODE_ENV === "test"
                ? "http://localhost"
                : "") + "/map{s}/by_webkarte/smerc/{z}/{x}/{y}"
            }
            subdomains="123456789"
          />
          <MapNavigator target={flyDestination} maxDistance={0.0005} />
          <MapClickListener
            onClick={(e) => {
              if (!ignoreClick && !props.disabled) {
                markerChange(e.latlng);
                setFlyDestination({dest: e.latlng, fly: false});
              }
            }}
          />
          <MapControls
            idBase={props.idBase + "LocationSelector"}
            smallPadding={true}
            center={() => {
              if (props.location) return props.location;
              else return props.initialLocation;
            }}
            beforeClick={() => setIgnoreClick(true)}
            afterClick={() => setIgnoreClick(false)}
          />
          {props.location ? (
            <Marker
              draggable={!props.disabled}
              eventHandlers={{
                drag: (e) => markerChange(e.target._latlng),
                dragend: () => {
                  if (props.location)
                    setFlyDestination({dest: props.location, fly: false});
                },
              }}
              position={props.location}
            ></Marker>
          ) : null}
        </MapContainer>
      </div>
      <div className="locationSelectorTextBar">
        <div className="textBarElement">
          <label
            htmlFor={props.idBase + "LatitudeTextInput"}
            className="latLongLabel"
          >
            Lat:
          </label>
          <input
            type="text"
            id={props.idBase + "LatitudeTextInput"}
            value={latLngText.lat}
            placeholder="—"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              latLngTextChange(e.target.value, true)
            }
            onBlur={() => {
              if (props.location)
                setFlyDestination({
                  dest: props.location,
                  fly: true,
                  ignoreMaxDistance: true,
                });
            }}
            className={"latLongInput" + (props.edited ? " edited" : "")}
            disabled={props.disabled}
          />
        </div>
        <div className="textBarElement">
          <label
            htmlFor={props.idBase + "LongitudeTextInput"}
            className="latLongLabel"
          >
            Lng:
          </label>
          <input
            type="text"
            id={props.idBase + "LongitudeTextInput"}
            value={latLngText.lng}
            placeholder="—"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              latLngTextChange(e.target.value, false)
            }
            onBlur={() => {
              if (props.location)
                setFlyDestination({
                  dest: props.location,
                  fly: true,
                  ignoreMaxDistance: true,
                });
            }}
            className={"latLongInput" + (props.edited ? " edited" : "")}
            disabled={props.disabled}
          />
        </div>
      </div>
    </div>
  );
};

export {LocationSelector};
