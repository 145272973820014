import React, {useEffect, useRef, useState} from "react";
import {executeSearch, SearchTreeNode} from "../../logic/SearchSuggestionsList";
import "./InputField.css";
import xCircle from "../../images/xCircle.svg";
import {FloatingButton, floatingButtonProps} from "./FloatingButton";

type inputFieldProps = {
  id: string;
  label: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  optionalLabel?: string;
  placeholder?: string;
  labelButton?: floatingButtonProps;
  hintText?: string;
  errorText?: string;
  edited?: boolean;
  onBlur?: () => void;
  afterInactivity?: () => void;
  autocomplete?: {
    suggestions: {[key: string]: string};
    tree: SearchTreeNode;
    ignoredIds?: {[key: string]: true};
  };
  disabled?: boolean;
  isPassword?: boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

type Suggestion = {
  id: string;
  text: string;
  matchStart: number;
  matchEnd: number;
};

/**
 * A string input field.
 *
 * @component
 * @props id: An unique HTML-ID to assign to the field
 * @props label: A text to display above the field
 * @props value: The currently entered text – can be implemented as a useState()-pair with setValue
 * @props setValue: How to update the currently entered text – can be implemented as a useState()-pair with value
 * @props optionalLabel?: Additional text to display next to the label
 * @props placeholder?: Alternative text to display as a placeholder instead of the label
 * @props labelButton?: Attributes for a floating button to display as part of the label
 * @props hintText?: An explanation of the field displayed underneath it
 * @props errorText?: An error message for incorrect inputs displayed in red with an icon underneath the field
 * @props edited?: Whether the location has been changed and thus italic text is to be used
 * @props onBlur?: A function to execute when the field looses focus
 * @props afterInactivity?: A function to execute after the contents were changed and then stayed the same for 200ms
 * @props autoComplete?: A list of valid inputs to be suggested – contains a map of ID to suggestion text, the search tree root and optionally a dictionary containing ids of suggestions not to display
 * @props disabled?: Whether the field's content can be edited
 * @props isPassword?: Whether it's a password field
 * @props onKeyUp?: A function to execute after the field receives key presses
 */
const InputField = (props: inputFieldProps) => {
  const [focused, setFocused] = useState(false);
  const [suggestions, setSuggestions] = useState<Array<Suggestion>>([]);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    recalculateSuggestions(props.value);
    if (props.afterInactivity)
      return () => {
        if (timerRef.current) clearTimeout(timerRef.current);
      };
  }, []);

  const recalculateSuggestions = (input: string) => {
    if (props.autocomplete) {
      const hits = executeSearch(input, props.autocomplete.tree, true);
      const preferredSuggestions: Array<Suggestion> = [];
      const suggestions: Array<Suggestion> = [];
      for (const h of hits) {
        if (
          props.autocomplete.ignoredIds &&
          h.id in props.autocomplete.ignoredIds
        )
          continue;
        const suggestion = {
          id: h.id,
          text: props.autocomplete.suggestions[h.id],
          matchStart: h.startIndex,
          matchEnd: h.startIndex + input.length,
        };
        if (h.startIndex === 0) preferredSuggestions.push(suggestion);
        else suggestions.push(suggestion);
      }
      setSuggestions(preferredSuggestions.concat(suggestions));
    }
  };

  return (
    <div className={"inputWrapper"}>
      <div className="inputContainer">
        <label className={"inputFieldLabel"} htmlFor={props.id}>
          <div className="labelContainer">
            <div className="labelTextContainer">
              <div className="labelText">{props.label}</div>
              {props.optionalLabel ? (
                <div className="labelTextOptional">{props.optionalLabel}</div>
              ) : null}
            </div>
            {props.labelButton ? (
              <div className="labelImageContainer">
                <FloatingButton {...props.labelButton} />
              </div>
            ) : null}
          </div>
        </label>
        <div className="inputFieldContainer">
          <input
            className={"inputField" + (props.edited ? " edited" : "")}
            type={props.isPassword ? "password" : "text"}
            id={props.id}
            placeholder={props.placeholder || props.label}
            value={props.value}
            autoComplete={props.autocomplete ? "off" : "on"}
            onKeyUp={props.onKeyUp ? props.onKeyUp : undefined}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              recalculateSuggestions(e.target.value.trim());
              props.setValue(e.target.value);
              if (props.afterInactivity) {
                if (currentTimeout) clearTimeout(currentTimeout);
                const to = setTimeout(() => {
                  if (props.afterInactivity) props.afterInactivity();
                  setCurrentTimeout(null);
                }, 200);
                timerRef.current = to;
                setCurrentTimeout(to);
              }
            }}
            onBlur={() => {
              setFocused(false);
              if (props.onBlur) {
                if (props.afterInactivity && currentTimeout) {
                  clearTimeout(currentTimeout);
                  props.afterInactivity();
                }
                props.onBlur();
              }
            }}
            onFocus={() => {
              if (props.autocomplete) recalculateSuggestions(props.value);
              setFocused(true);
            }}
            disabled={props.disabled}
          ></input>
        </div>
        <div className={"inputUnderline" + (focused ? " focused" : "")} />
        <div className="inputAmendment">
          <div className="hintText">{props.hintText}</div>
          {props.errorText ? (
            <div className="errorTextContainer">
              <img className="errorIcon" src={xCircle} />
              <div className="errorText">{props.errorText}</div>
            </div>
          ) : null}
        </div>
      </div>
      {focused &&
      props.autocomplete &&
      !(
        suggestions.length == 1 && props.value.trim() == suggestions[0].text
      ) ? (
        <div className="autoCompletionContainer">
          {suggestions.length == 0 ? (
            <div className="suggestionLine">
              <span className="suggestionText">Keine Treffer</span>
            </div>
          ) : (
            suggestions.map((s) => (
              <div
                key={s.id}
                className="suggestionLine"
                onMouseDown={() => {
                  props.setValue(s.text);
                  recalculateSuggestions(s.text);
                }}
              >
                <span className="suggestionText">
                  {s.text.substring(0, s.matchStart)}
                </span>
                {s.matchStart > 0 && s.text[s.matchStart - 1] === " " ? (
                  <span>&nbsp;</span>
                ) : null}
                <span className="suggestionText match">
                  {s.text.substring(s.matchStart, s.matchEnd)}
                </span>
                {s.text[s.matchEnd] === " " ? <span>&nbsp;</span> : null}
                <span className="suggestionText">
                  {s.text.substring(s.matchEnd)}
                </span>
              </div>
            ))
          )}
        </div>
      ) : null}
    </div>
  );
};

export {InputField};
