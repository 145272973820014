import React, {useState} from "react";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import App from "./views/App";
import keycloak from "./logic/Keycloak";

function KeycloakInitWrapper() {
  const [keycloakInitError, setKeycloakInitError] = useState(false);

  const keycloakInitOptions = {
    onLoad: "check-sso",
    redirectUri: location.href,
    pkceMethod: "S256",
    messageReceiveTimeout:
      window.location.hostname === "smartcityhub.smartandpublic.eu"
        ? undefined
        : 3000,
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitOptions}
      onEvent={(evType) => {
        if (evType === "onInitError") setKeycloakInitError(true);
      }}
    >
      <React.StrictMode>
        <App keycloakInitError={keycloakInitError} />
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
}

export default KeycloakInitWrapper;
