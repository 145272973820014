import React, {MouseEventHandler} from "react";
import "./FloatingButton.css";

type floatingButtonProps = {
  id: string;
  image: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  ignoreRounding?: boolean;
  withoutBackground?: boolean;
  greyOut?: boolean;
};

/**
 * A per-default round button only consisting of an icon.
 *
 * @component
 * @props id: An unique HTML-ID to assign to the button
 * @props image: The path to an image to display on the button
 * @props onClick: A function to execute when the button is clicked
 * @props disabled?: Whether the button can be clicked
 * @props ignoreRounding?: Whether the button should be rectangular
 * @props withoutBackground?: Whether the button should have a transparent background
 * @props greyOut?: Whether the button is greyed out when disabled
 */
const FloatingButton = (props: floatingButtonProps) => {
  return (
    <button
      id={props.id}
      className={
        "floatingButtonContainer" +
        (props.ignoreRounding ? " ignoreRounding" : "") +
        (props.withoutBackground ? " withoutBackground" : "")
      }
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <img
        className={
          "floatingButtonIcon" +
          (props.greyOut && props.disabled ? " greyOut" : "")
        }
        src={props.image}
      />
    </button>
  );
};

export {FloatingButton, type floatingButtonProps};
