import React from "react";
import L, {IconOptions} from "leaflet";
import {Marker} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import marker0 from "../../images/marker0.svg";
import marker1 from "../../images/marker1.svg";
import marker2 from "../../images/marker2.svg";
import marker3 from "../../images/marker3.svg";
import "./TreeMarkerRenderer.css";
import {TreeData} from "../../logic/APITypes";
import recordToIterable from "../../logic/RecordToIterable";

/**
 * Paints markers for the trees and associated info.
 *
 * @component
 * @props treeData: A list of trees to render as markers
 * @props openedTree: State tracking for the currently opened tree info popup
 */
const TreeMarkerRenderer = (props: {
  treeData: Record<number, TreeData>;
  openedTree: {get: number | null; set: (treeId: number | null) => void};
}) => {
  const markerInfo: IconOptions = {
    iconUrl: icon,
    iconSize: [37, 53],
    iconAnchor: [19, 53],
    popupAnchor: [0, -45],
  };
  const DefaultIcon = L.icon({
    ...markerInfo,
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <>
      {recordToIterable(props.treeData).map((tree) => (
        <Marker
          opacity={
            props.openedTree.get && props.openedTree.get !== tree.id ? 0.5 : 1
          }
          eventHandlers={{
            click: () =>
              props.openedTree.set(
                props.openedTree.get === tree.id ? null : tree.id
              ),
          }}
          title={tree.tree_number}
          position={L.latLng(tree.lat, tree.long)}
          key={tree.tree_number}
          icon={L.icon({
            ...markerInfo,
            iconUrl: [marker3, marker2, marker1, marker1, marker1, marker0][
              tree.ground_moisture_level?.category === undefined
                ? 5
                : tree.ground_moisture_level.category
            ],
          })}
        />
      ))}
    </>
  );
};

export default TreeMarkerRenderer;
