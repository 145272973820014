import {useEffect} from "react";
import {useMap} from "react-leaflet";

type MapNavigatorProps = {
  target: {dest: L.LatLng; fly: boolean; ignoreMaxDistance?: boolean};
  maxDistance: number;
};

/**
 * A hidden component that allows manipulation of a map's viewport by updating the target prop. Has to be placed inside a leaflet MapContainer.
 *
 * @component
 * @props target: A dictionary indicating the next navigation action. Consists of dest (what location to go to), fly (whether to use the fly animation instead of panning) and ignoreMaxDistance? (whether to visit the location even if moving more than the maxDistance prop allows)
 * @props maxDistance: An inaccurate upper bound prohibiting navigation over distances too great
 */
const MapNavigator = (props: MapNavigatorProps) => {
  const map = useMap();
  useEffect(() => {
    if (
      props.target.ignoreMaxDistance ||
      (map.getCenter().lat - props.target.dest.lat) ** 2 +
        (map.getCenter().lng - props.target.dest.lng) ** 2 <
        props.maxDistance
    ) {
      if (props.target.fly) map.flyTo(props.target.dest);
      else map.panTo(props.target.dest);
    }
  }, [props.target]);
  return null;
};

export {MapNavigator};
