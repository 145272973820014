import React, {useState} from "react";
import "./AddTreeConfirmationModal.css";
import arrowLeft from "../../images/arrowLeft.svg";
import API from "../../logic/API";
import {LabeledButton} from "../../reusables/basic/LabeledButton";
import {LocationPresenter} from "../../reusables/compound/LocationPresenter";
import {SoilData} from "../../logic/APITypes";

type addTreeConfirmationModalProps = {
  location: L.LatLng;
  gerSpecies: string;
  latSpecies: string;
  speciesId: number;
  treeNumber: string;
  onTreeAdded: (id: number) => void;
  goBack: () => void;
  sensor?: {eUid: string; id: number};
  soil?: SoilData;
  user: {logOut: () => void; isAdmin: boolean};
};

/**
 * Displays a summary of entered data when adding a new tree to confirm the values.
 *
 * @component
 * @props location: The Lat/Lng-position of the tree to add
 * @props gerSpecies: The german species name of the tree to add
 * @props latSpecies: The latin species name of the tree to add
 * @props treeNumber: The treeNumber of the tree to add
 * @props onTreeAdded: A function to call with the id of the tree (primary key) after the API call to create the tree succeeds
 * @props goBack: A function to call when the "go back"-button is clicked
 * @props sensor: Info about the linked sensor, if one was added
 * @props soil: The type of soil composition, if one was specified
 * @props user: An object to manage the user – contains the status of whether they're an admin and a function to log them out
 */
const AddTreeConfirmationModal = (props: addTreeConfirmationModalProps) => {
  const [waiting, setWaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div className="addTreeConfirmationModal">
      <button
        disabled={waiting}
        onClick={() => props.goBack()}
        className="leaveAddTree"
        id="addTreeConfirmationModalGoBackButton"
      >
        <img className="arrowLeft" src={arrowLeft} />
      </button>
      <div className="addTreeConfirmationModalContainer">
        <div className="addTreeConfirmationTitle">Überprüfe die Baumdaten</div>
        <div className="locationPresenterContainer">
          <LocationPresenter
            location={props.location}
            idBase={"addTreeConfirmationModal"}
          />
        </div>
        <div className="informationSummaryContainer">
          <div className="informationSummaryContainer2">
            <div className="informationSummaryLatSpecies">
              {props.latSpecies}
            </div>
            <div className="informationSummarySecondaryInfo">
              <div>{props.gerSpecies}</div>
              <div className="informationSummaryTreeNumber">
                {props.treeNumber}
              </div>
            </div>
            {props.sensor || props.soil ? (
              <div className="informationSummarySecondaryInfo">
                {props.sensor ? <div>{props.sensor.eUid}</div> : null}
                {props.soil ? (
                  <div
                    className={
                      "informationSummarySecondaryRight" +
                      (props.sensor ? "" : " noSensor")
                    }
                  >
                    {props.soil.name}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <div className="informationSummaryButtonContainer">
          <LabeledButton
            id="addTreeConfirmationModalConfirmButton"
            label="Hinzufügen"
            importance="primary"
            disabled={waiting}
            onClick={() => {
              setWaiting(true);
              API.addTree(
                props.treeNumber,
                props.speciesId,
                props.location.lat,
                props.location.lng,
                props.sensor?.id,
                props.soil?.id
              ).then((res) => {
                if (res.success) {
                  if (res.payload?.data?.id)
                    props.onTreeAdded(res.payload.data.id);
                  setErrorMessage("");
                } else if (res.httpCode === 403) props.user.logOut();
                else {
                  if (res.errorReason) {
                    if (res.errorReason.includes("Failed to fetch"))
                      setErrorMessage("Server konnte nicht erreicht werden");
                    else setErrorMessage(res.errorReason);
                  }
                }
                setWaiting(false);
              });
            }}
          />
        </div>
        <div className="informationSummaryErrorContainer">
          <div>{errorMessage}</div>
        </div>
      </div>
    </div>
  );
};

export {AddTreeConfirmationModal};
