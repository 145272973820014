import React, {useEffect, useRef, useState} from "react";
import L from "leaflet";
import {MapContainer, TileLayer} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {TreeData} from "../../logic/APITypes";
import TreeMarkerRenderer from "./TreeMarkerRenderer";
import {MapControls} from "../../reusables/map/MapControls";
import recordToIterable from "../../logic/RecordToIterable";

/**
 * Sets up and displays the leaflet map.
 *
 * @component
 * @props treeData: A list of trees to represent as markers on the map
 * @props probeWatering: A function to call when the user requests watering of a individual tree
 * @props isAdmin: Whether the user is an admin
 * @props openedTree: State tracking for the currently opened tree info popup
 */
const MapPanel = (props: {
  treeData: Record<number, TreeData>;
  probeWatering: (id: number) => void;
  isAdmin: boolean;
  openedTree: {get: number | null; set: (treeId: number | null) => void};
}) => {
  const urlPrefix =
    process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
      ? "http://localhost"
      : "";
  const [focusedMarker, setFocusedMarker] = useState(0);
  const [currentLayerIndex, setCurrentLayerIndex] = useState(0);
  const ref = useRef<L.TileLayer | null>(null);
  useEffect(() => {
    if (ref.current)
      ref.current.setUrl(
        urlPrefix +
          "/map{s}/by_" +
          layers[currentLayerIndex] +
          "/smerc/{z}/{x}/{y}"
      );
  }, [currentLayerIndex]);

  const layers = ["webkarte", "webkarte_grau", "amtl_karte", "dop", "dop"];
  const attributions = [
    "&copy; Datenquellen: <a href='https://vermessung.bayern.de/file/pdf/7203/Nutzungsbedingungen_Viewing.pdf' target='_blank' rel='noopener noreferrer'>Bayerische Vermessungsverwaltung</a>, GeoBasis-DE / <a href='https://www.bkg.bund.de/DE/Home/home.html' target='_blank' rel='noopener noreferrer'>BKG</a> 2023 – Daten verändert",
    "&copy; Datenquellen: <a href='https://vermessung.bayern.de/file/pdf/7203/Nutzungsbedingungen_Viewing.pdf' target='_blank' rel='noopener noreferrer'>Bayerische Vermessungsverwaltung</a>, GeoBasis-DE / <a href='https://www.bkg.bund.de/DE/Home/home.html' target='_blank' rel='noopener noreferrer'>BKG</a> 2023 – Daten verändert",
    "&copy; Datenquellen: <a href='https://vermessung.bayern.de/file/pdf/7203/Nutzungsbedingungen_Viewing.pdf' target='_blank' rel='noopener noreferrer'>Bayerische Vermessungsverwaltung</a>, GeoBasis-DE / <a href='https://www.bkg.bund.de/DE/Home/home.html' target='_blank' rel='noopener noreferrer'>BKG</a> 2021/2023 – Daten verändert",
    "&copy; Datenquellen: <a href='https://vermessung.bayern.de/file/pdf/7203/Nutzungsbedingungen_Viewing.pdf' target='_blank' rel='noopener noreferrer'>Bayerische Vermessungsverwaltung</a>, Europäische Union, enthält Copernicus Sentinel-2 Daten 2018, verarbeitet durch das Bundesamt für Kartographie und Geodäsie (BKG)",
    "&copy; Datenquellen: <a href='https://vermessung.bayern.de/file/pdf/7203/Nutzungsbedingungen_Viewing.pdf' target='_blank' rel='noopener noreferrer'>Bayerische Vermessungsverwaltung</a>, GeoBasis-DE / <a href='https://www.bkg.bund.de/DE/Home/home.html' target='_blank' rel='noopener noreferrer'>BKG</a> 2023 – Daten verändert, Europäische Union, enthält Copernicus Sentinel-2 Daten 2018, verarbeitet durch das Bundesamt für Kartographie und Geodäsie (BKG)",
  ];
  const centerMap = () => {
    let top = Number.MIN_SAFE_INTEGER;
    let right = Number.MIN_SAFE_INTEGER;
    let bottom = Number.MAX_SAFE_INTEGER;
    let left = Number.MAX_SAFE_INTEGER;
    for (const id in props.treeData) {
      const t = props.treeData[id];
      top = Math.max(t.lat, top);
      right = Math.max(t.long, right);
      bottom = Math.min(t.lat, bottom);
      left = Math.min(t.long, left);
    }
    return new L.LatLngBounds([
      [top, right],
      [bottom, left],
    ]);
  };
  const nextMarker = () => {
    const trees = recordToIterable(props.treeData);
    const next = (focusedMarker + 1) % trees.length;
    setFocusedMarker(next);
    return new L.LatLng(trees[next].lat, trees[next].long);
  };
  return (
    <div className={"mapPanel"}>
      <MapContainer
        center={L.latLng(49.79, 9.95)}
        zoom={15}
        scrollWheelZoom={false}
        zoomControl={false}
      >
        <TileLayer
          attribution={attributions[currentLayerIndex]}
          url={
            urlPrefix +
            "/map{s}/by_" +
            layers[currentLayerIndex] +
            "/smerc/{z}/{x}/{y}"
          }
          subdomains="123456789"
          ref={ref}
        />
        {currentLayerIndex === 4 ? (
          <TileLayer
            attribution=""
            url={urlPrefix + "/map{s}/by_label/smerc/{z}/{x}/{y}"}
            subdomains="123456789"
          />
        ) : null}
        <MapControls
          idBase="mapPanel"
          smallPadding={false}
          center={centerMap}
          nextMarker={nextMarker}
          onCycleLayer={() => setCurrentLayerIndex((currentLayerIndex + 1) % 5)}
        />
        <TreeMarkerRenderer {...props} />
      </MapContainer>
    </div>
  );
};

export {MapPanel};
