/**
 * Converts a record into a readonly array for easy iterations.
 */
const recordToIterable = <K extends string | number | symbol, V>(
  record: Record<K, V>
) => {
  const iterable: Array<V> = [];
  for (const key in record) {
    iterable.push(record[key]);
  }
  return iterable as ReadonlyArray<V>;
};

export default recordToIterable;
