import {LeafletMouseEventHandlerFn} from "leaflet";
import {useMapEvents} from "react-leaflet";

/**
 * A hidden component that allows implementation of click handling on the map in a parent component. Has to be placed inside a leaflet MapContainer.
 *
 * @component
 * @props onClick: A function to execute when a location in the map is clicked
 */
const MapClickListener = (props: {onClick: LeafletMouseEventHandlerFn}) => {
  useMapEvents({
    click: props.onClick,
  });
  return null;
};

export {MapClickListener};
