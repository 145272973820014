import React from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./MapControls.css";
import layersIcon from "../../images/layersIcon.svg";
import centerIcon from "../../images/centerCrosshair.svg";
import zoomInIcon from "../../images/plusCircle.svg";
import zoomOutIcon from "../../images/minusCircle.svg";
import markerIcon from "../../images/locationMarker.svg";
import {FloatingButton} from "../basic/FloatingButton";
import {useMap} from "react-leaflet";

type mapControlsProps = {
  idBase: string;
  smallPadding: boolean;
  center: () => L.LatLngBounds | L.LatLng;
  nextMarker?: () => L.LatLng;
  disabledDoubleClickZoom?: boolean;
  beforeClick?: () => void;
  afterClick?: () => void;
  onCycleLayer?: () => void;
};

/**
 * A button group to overlay maps for replaced zoom controls and other utilities.
 *
 * @component
 * @props idBase: A prefix for the HTML-IDs of elements in the overlay. Should reflect the path of entrypoints
 * @props smallPadding: Whether to place the buttons closer to the edge of the map
 * @props center: A function to provide a location to fly to when the crosshair button is pressed
 * @props nextMarker?: A function to provide a location to fly to when the marker button is pressed
 * @props disabledDoubleClickZoom?: Whether the map should have double clicking for zooming disabled
 * @props beforeClick?: A function to execute on mouse down in the button group, e.g. to briefly disable a click listener on the map
 * @props afterClick?: A function to execute after mouse up in the button group, e.g. to reenable a click listener on the map
 * @props onCycleLayer?: A function to cycle through available map styles when the layers button is pressed
 */
const MapControls = (props: mapControlsProps) => {
  const map = useMap();
  return (
    <div
      className={
        "mapControlContainer" + (props.smallPadding ? " smallPadding" : "")
      }
      onMouseDown={() => {
        map.doubleClickZoom.disable();
        if (props.beforeClick) props.beforeClick();
      }}
      onMouseUp={() => {
        setTimeout(() => {
          if (!props.disabledDoubleClickZoom) map.doubleClickZoom.enable();
          if (props.afterClick) props.afterClick();
        }, 0);
      }}
    >
      {props.onCycleLayer ? (
        <div className="mapControlButton">
          <FloatingButton
            id={props.idBase + "MapControlsLayers"}
            image={layersIcon}
            onClick={() => {
              if (props.onCycleLayer) props.onCycleLayer();
            }}
          />
        </div>
      ) : null}
      {props.nextMarker ? (
        <div className="mapControlButton">
          <FloatingButton
            id={props.idBase + "MapControlsCenter"}
            image={markerIcon}
            onClick={() => {
              if (props.nextMarker) map.flyTo(props.nextMarker());
            }}
          />
        </div>
      ) : null}
      <div className="mapControlButton">
        <FloatingButton
          id={props.idBase + "MapControlsCenter"}
          image={centerIcon}
          onClick={() => {
            const destination = props.center();
            if (destination instanceof L.LatLng) map.flyTo(destination);
            else map.flyToBounds(destination);
          }}
        />
      </div>
      <div className="mapControlButton">
        <FloatingButton
          id={props.idBase + "MapControlsZoomIn"}
          image={zoomInIcon}
          onClick={() => map.zoomIn()}
        />
      </div>
      <div className="mapControlButton">
        <FloatingButton
          id={props.idBase + "MapControlsZoomOut"}
          image={zoomOutIcon}
          onClick={() => map.zoomOut()}
        />
      </div>
    </div>
  );
};

export {MapControls};
