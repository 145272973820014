import {TreeData, SpeciesData, SensorData, SoilData} from "./APITypes";
import keycloak from "./Keycloak";

/**
 * Enum for REST-request methods
 * @type
 */
type Method = "POST" | "GET" | "PATCH" | "PUT" | "DELETE";
/**
 * API-requests return a promise resolving to this type.
 * @type
 * @property success
 * @property httpCode
 * @property errorReason - Missing if successful
 * @property payload - Missing if unsuccessful
 */
type Resolution<T> = {
  success: boolean;
  httpCode: number;
  errorReason?: string;
  payload?: T;
};

/**
 * @namespace API
 * @desc Provides an interface to operations in the backend.
 */
const API = {
  url:
    process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
      ? "http://localhost:8001/api/"
      : "/api/",
  /**
   * Fetches a resource using the appropriate headers.
   * @function
   * @param path - relative path of the resource
   * @param withCredentials - whether to include the keycloak authorization header
   */
  fetchWithHeaders: (
    path: string,
    method?: Method,
    withCredentials?: boolean,
    body?: object
  ) => {
    const header: HeadersInit = new Headers();
    header.set("Content-Type", "application/json");
    if (withCredentials)
      header.set("Authorization", "Bearer " + keycloak.token);
    const request: RequestInit = {
      method: method,
      headers: header,
      credentials:
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "test"
          ? "include"
          : "same-origin",
    };
    if (body) request.body = JSON.stringify(body);
    return fetch(API.url + path, request);
  },
  /**
   * Evaluates whether a fetch succeeds.
   * @function
   * @param request - the fetched promise to evaluate
   * @returns a non-rejecting promise
   */
  resolveFetch: async <T>(
    request: Promise<Response>
  ): Promise<Resolution<T>> => {
    return new Promise((resolve) => {
      request.then(
        (response) => {
          if (response.ok) {
            const len = response.headers.get("content-length");
            if (!len || +len == 0)
              resolve({success: true, httpCode: response.status});
            else
              response.json().then(
                (json) => {
                  resolve({
                    success: true,
                    httpCode: response.status,
                    payload: json,
                  });
                },
                (errorReason) => {
                  resolve({
                    success: false,
                    httpCode: response.status,
                    errorReason: errorReason,
                  });
                }
              );
          } else {
            resolve({
              success: false,
              httpCode: response.status,
              errorReason: response.statusText,
            });
          }
        },
        (errorReason) => {
          resolve({
            success: false,
            httpCode: 400,
            errorReason: errorReason.toString(),
          });
        }
      );
    });
  },
  /**
   * Attempts to get a list of all trees.
   * @function
   */
  getTrees: async () => {
    return API.resolveFetch<Array<TreeData>>(
      API.fetchWithHeaders("tree", "GET", true)
    );
  },
  /**
   * Attempts to get a tree by its' tree id.
   * @function
   */
  getTreeByTreeNumber: async (treeNumber: string) => {
    return API.resolveFetch<TreeData>(
      API.fetchWithHeaders("tree/tree_number/" + treeNumber, "GET", true)
    );
  },
  /**
   * Attempts to get a tree by its' id.
   * @function
   */
  getTreeById: async (id: number) => {
    return API.resolveFetch<TreeData>(API.fetchWithHeaders("tree/id/" + id));
  },
  /**
   * Attempts to add a new tree.
   * @function
   */
  addTree: async (
    treeNumber: string,
    speciesId: number,
    lat: number,
    lng: number,
    sensorId?: number,
    soilId?: number
  ) => {
    const attributes: Record<string, string | number> = {
      tree_number: treeNumber,
      species: speciesId,
      lat: lat,
      long: lng,
    };
    if (sensorId) attributes["sensor"] = sensorId;
    if (soilId) attributes["soil_composition"] = soilId;
    return API.resolveFetch<{status: string; data: TreeData}>(
      API.fetchWithHeaders("tree", "POST", true, attributes)
    );
  },
  /**
   * Attempts to edit a tree.
   * @function
   */
  editTree: async (
    id: number,
    changes: {
      tree_number?: string;
      species?: number;
      lat?: number;
      lng?: number;
      sensor?: number | null;
      soil_composition?: number | null;
    }
  ) => {
    return API.resolveFetch(
      API.fetchWithHeaders("tree/id/" + id, "PATCH", true, changes)
    );
  },
  /**
   * Attempts to delete a tree.
   * @function
   */
  deleteTree: async (id: number) => {
    return API.resolveFetch(
      API.fetchWithHeaders("tree/id/" + id, "DELETE", true)
    );
  },
  /**
   * Attempts to get a list of all species.
   * @function
   */
  getSpecies: async () => {
    return API.resolveFetch<Array<SpeciesData>>(
      API.fetchWithHeaders("species", "GET", true)
    );
  },
  /**
   * Attempts to get a list of all sensors.
   * @function
   */
  getSensors: async () => {
    return API.resolveFetch<Array<SensorData>>(API.fetchWithHeaders("sensors"));
  },
  /**
   * Attempts to edit a sensor.
   * @function
   */
  editSensor: async (id: number, changes: {name?: string}) => {
    return API.resolveFetch(
      API.fetchWithHeaders("sensors/" + id, "PATCH", true, changes)
    );
  },
  /**
   * Attempts to get a list of all types of soil compositions.
   * @function
   */
  getSoils: async () => {
    return API.resolveFetch<Array<SoilData>>(
      API.fetchWithHeaders("soil_composition", "GET", true)
    );
  },
  /**
   * Attempts to remove the link between a tree and its sensor.
   * @function
   * @param id - pk of the tree
   */
  unlinkSensorFromTree: async (id: number) => {
    return API.resolveFetch(
      API.fetchWithHeaders("assignment/" + id, "DELETE", true)
    );
  },
  /**
   * Attempts to trigger the sending of a watering command to the ticket system.
   * @function
   * @param id - id of the tree
   */
  sendWateringCommand: async (id: number) => {
    return API.resolveFetch<null>(
      API.fetchWithHeaders("watering/" + id, "POST")
    );
  },
};

export default API;
