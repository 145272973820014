import React, {useState} from "react";
import "./TreeMarkerRenderer.css";
import wateringCan from "../../images/wateringCanWhite.svg";
import xCircle from "../../images/xCircle.svg";
import {TreeData} from "../../logic/APITypes";
import {LabeledButton} from "../../reusables/basic/LabeledButton";
import {FloatingButton} from "../../reusables/basic/FloatingButton";
import drop1 from "../../images/drop1.svg";
import drop2 from "../../images/drop2.svg";
import drop3 from "../../images/drop3.svg";

/**
 * The popup shown after a click on a tree-marker. Contains information about the tree.
 *
 * @component
 * @props tree: Info regarding the tree represented by the marker
 * @props probeWatering: A function to call when the user requests watering of a individual tree
 * @props isAdmin: Whether the user is an admin
 * @props closeMarker: A function to call when the marker modal should disappear
 * @props openedTree: State tracking for the currently opened tree info popup
 */
const TreeMarkerPopup = (props: {
  tree: TreeData;
  probeWatering: (id: number) => void;
  isAdmin: boolean;
  closeMarker: () => void;
  openedTree: {get: number | null; set: (treeId: number | null) => void};
}) => {
  const [activeTab, setActiveTab] = useState<"general" | "details">(
    props.isAdmin ? "details" : "general"
  );

  const groundMoistureToValueEntry = (groundMoisture: number | undefined) => {
    return (
      <span className="valueEntry">
        {groundMoisture === undefined || groundMoisture === null
          ? "—"
          : Math.round((groundMoisture + Number.EPSILON) * 100) / 100 + "%"}
      </span>
    );
  };

  const markerTexts = [
    "Die Erde an dem Baum ist zu trocken. Sie sollte nicht für eine längere Zeit in diesem Zustand bleiben.",
    "Der Baum steht etwas zu trocken und wird in nächster Zeit Wasser benötigen, um nicht in einen Wasserdefizit zu rutschen.",
    "Der Baum hat genau die richtige Menge Wasser zur Verfügung. Der Boden ist weder zu feucht, noch zu trocken",
    "Der Baum steht etwas zu feucht. Ihm würde eine etwas geringere Bodenfeuchte guttun.",
    "Die Erde an dem Baum ist zu feucht. Sie sollte nicht für eine längere Zeit in diesem Zustand bleiben.",
    "Für diesen Baum liegen momentan keine Messwerte vor.",
  ];

  const circleColors = [
    "#E32126",
    "#FFF353",
    "#019646",
    "#6E9BD1",
    "#172F70",
    "#9B9B9B",
  ];

  return (
    <div data-testid="treeMarkerPopup" className="markerContainer">
      <FloatingButton
        id={"markerCloseButton"}
        image={xCircle}
        onClick={() => props.closeMarker()}
      />
      <div className="markerTitle">Bauminformation</div>
      <div className="markerTitle">
        {activeTab === "general"
          ? props.tree.species.species_german
          : props.tree.species.species_latin}
      </div>
      <div className="markerTabs">
        <button
          className={activeTab === "general" ? "active" : ""}
          onClick={() => setActiveTab("general")}
        >
          Allgemein
        </button>
        <button
          className={activeTab === "details" ? "active" : ""}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <div className={"markerTabLine " + activeTab} />
      </div>
      {activeTab === "general" ? (
        <div className="markerBody">
          <div
            className="markerPopupCircle"
            style={{
              backgroundColor:
                circleColors[
                  props.tree.ground_moisture_level
                    ? props.tree.ground_moisture_level.category
                    : 5
                ],
            }}
          >
            <div className="markerPopupIcon"></div>
            <img
              src={
                [drop1, drop2, drop3, drop3, drop3, drop1][
                  props.tree.ground_moisture_level
                    ? props.tree.ground_moisture_level.category
                    : 0
                ]
              }
            />
          </div>

          <div className="markerText">
            {
              markerTexts[
                props.tree.ground_moisture_level
                  ? props.tree.ground_moisture_level.category
                  : 5
              ]
            }
          </div>
        </div>
      ) : (
        <div className="markerTable">
          <span>Baumnummer</span>
          <span>{props.tree.tree_number}</span>
          <span>Bodenart</span>
          <span>
            {props.tree.soil_composition
              ? props.tree.soil_composition.name
              : "—"}
          </span>
          <span>
            VWC<sub>30</sub>
          </span>
          {groundMoistureToValueEntry(
            props.tree.ground_moisture_level?.volume_water_content_upper
          )}
          <span>
            VWC<sub>100</sub>
          </span>
          {groundMoistureToValueEntry(
            props.tree.ground_moisture_level?.volume_water_content_lower
          )}
          <span>
            nFK<sub>30</sub>
          </span>
          {groundMoistureToValueEntry(
            props.tree.ground_moisture_level?.usable_field_capacity_upper
          )}
          <span>
            nFK<sub>100</sub>
          </span>
          {groundMoistureToValueEntry(
            props.tree.ground_moisture_level?.usable_field_capacity_lower
          )}
          <span>Status</span>
          <svg width="94" height="14">
            {["#E32126", "#FFF353", "#019646"].map((c, i) => (
              <rect
                key={"statusRect" + i}
                x={20 * i + 1}
                y="1"
                width="12"
                height="12"
                strokeWidth="2"
                stroke={c}
                fill={
                  props.tree.ground_moisture_level?.category === i
                    ? c
                    : (props.tree.ground_moisture_level?.category === 3 ||
                        props.tree.ground_moisture_level?.category === 4) &&
                      i === 2
                    ? "#019646"
                    : "rgba(0,0,0,0)"
                }
              />
            ))}
          </svg>
        </div>
      )}
      <div className="markerPopupButton">
        <LabeledButton
          id={`popup${props.tree.tree_number}RequestWateringButton`}
          label="Gießen"
          imageAfter={wateringCan}
          importance="primary"
          onClick={() => props.probeWatering(props.tree.id)}
        />
      </div>
    </div>
  );
};

export default TreeMarkerPopup;
