type SearchTreeNode = {
  children: Record<string, SearchTreeNode>;
  hits: Array<{id: string; startIndex: number}>;
};

/**
 * Builds a tree for efficient searches on a list of suggestions
 *
 * @param suggestions id to text dictionary
 * @param ignoreCase treat everything as lowercase
 * @returns
 */
const buildSearchTree = (
  suggestions: {[id: string]: string},
  ignoreCase: boolean
) => {
  const tree: SearchTreeNode = {children: {}, hits: []};
  for (const id in suggestions) {
    const s = suggestions[id];
    tree.hits.push({id, startIndex: -1});
    for (let startIndex = 0; startIndex < s.length; startIndex++) {
      let layer = tree;
      for (let c of s.substring(startIndex)) {
        if (ignoreCase) c = c.toLowerCase();
        if (!layer.children[c]) layer.children[c] = {children: {}, hits: []};
        layer.children[c].hits.push({id, startIndex});
        layer = layer.children[c];
      }
    }
  }
  cleanUpSubtree(tree, suggestions);
  return tree;
};

const cleanUpSubtree = (
  tree: SearchTreeNode,
  suggestions: {[id: string]: string}
) => {
  const ids: {[id: string]: boolean} = {};
  const cleanHits = [];
  for (let i = 0; i < tree.hits.length; i++) {
    if (!(tree.hits[i].id in ids)) cleanHits.push(tree.hits[i]);
    ids[tree.hits[i].id] = true;
  }
  tree.hits = cleanHits.sort((a, b) =>
    suggestions[a.id].localeCompare(suggestions[b.id], "de", {numeric: true})
  );
  for (const char in tree.children)
    cleanUpSubtree(tree.children[char], suggestions);
};

/**
 * Searches for suggestions containing the input.
 *
 * @param input string to search
 * @param tree previously built search tree
 * @param ignoreCase treat everything as lowercase
 * @returns list of objects providing suggestion id and start index of the match
 */
const executeSearch = (
  input: string,
  tree: SearchTreeNode,
  ignoreCase: boolean
) => {
  let layer = tree;
  let finished = true;
  if (ignoreCase) input = input.toLowerCase();
  for (const c of input) {
    if (layer.children[c]) layer = layer.children[c];
    else {
      finished = false;
      break;
    }
  }
  if (finished) return layer.hits;
  else return [];
};

/**
 * Debug function for visualizing the search tree.
 * @param layer which node to start printing from, when calling externally usually a previously build tree
 * @param letter prefix for each print line, when calling externally usually an empty string
 * @param suggestions id to text dictionary
 */
const printSearchTree = (
  layer: SearchTreeNode,
  letter: string,
  suggestions: {[id: number]: string}
) => {
  let hit;
  for (const h of layer.hits) {
    hit = h;
    break;
  }
  console.log(
    letter +
      ": " +
      layer.hits.length +
      " hits - e.g. " +
      (hit ? hit + ": " + suggestions[+hit.id] : "-")
  );
  for (const c in layer.children)
    printSearchTree(layer.children[c], letter + c, suggestions);
};

export {buildSearchTree, executeSearch, printSearchTree, type SearchTreeNode};
