import React, {useEffect, useState} from "react";
import "./ListModal.css";
import xCircle from "../../images/xCircle.svg";
import wateringCan from "../../images/wateringCan.svg";
import plusCircleInverted from "../../images/plusCircleInverted.svg";
import {LabeledButton} from "../../reusables/basic/LabeledButton";
import {FloatingButton} from "../../reusables/basic/FloatingButton";
import {CellData, Table} from "../../reusables/compound/Table";
import {
  SensorData,
  SoilData,
  SpeciesData,
  TreeData,
} from "../../logic/APITypes";
import {AddTreeModal} from "./AddTreeModal";
import {SearchTreeNode} from "../../logic/SearchSuggestionsList";
import API from "../../logic/API";
import recordToIterable from "../../logic/RecordToIterable";

type ListModalProps = {
  closeModal: () => void;
  treeData: Record<number, TreeData>;
  sensor: {
    data: Record<number, SensorData>;
    setData: (data: Record<number, SensorData>) => void;
    autocomplete: {
      suggestions: {[key: string]: string};
      tree: SearchTreeNode;
    };
  };
  speciesData: Record<number, SpeciesData>;
  speciesAutocomplete:
    | {
        suggestions: {[key: string]: string};
        tree: SearchTreeNode;
      }
    | undefined;
  soilData: Record<number, SoilData>;
  soilAutocomplete:
    | {
        suggestions: {[key: string]: string};
        tree: SearchTreeNode;
      }
    | undefined;
  addNewTree: (tree: TreeData) => void;
  editTree: (tree: TreeData) => void;
  deleteTree: (id: number) => void;
  renameSensor: (id: number, name: string) => void;
  probeWatering: (id: number) => void;
  user: {logOut: () => void; isAdmin: boolean};
};

/**
 * Displays an overlay with a table, listing the trees (or later on sensors) and associated information.
 *
 * @component
 * @props closeModal: A function to call when the modal should disappear
 * @props treeData: A list of trees in the system
 * @props sensor: State tracking for the list of sensors in the system – contains the current list (data), a function to update it (setData),
 * as well as a map of ID to suggestion text and the search tree root for the sensor dropdown
 * @props speciesData: A list of species in the system
 * @props speciesAutoComplete: A list of valid tree species to be suggested – contains a map of ID to suggestion text and the search tree root
 * @props soilData: A list of types of soil compositions in the system
 * @props soilAutoComplete: A list of valid types of soil compositions to be suggested – contains a map of ID to suggestion text and the search tree root
 * @props addNewTree: A function to call with data for a new tree to add when a tree was successfully created
 * @props editTree: A function to call with data for a modified tree to update when it was successfully edited
 * @props deleteTree: A function to call with the id of a tree to remove when it was successfully deleted
 * @props renameSensor: A function to call with the id of a sensor to edit and a name to call it
 * @props probeWatering: A function to call when the user requests watering of a individual tree
 * @props user: An object to manage the user – contains the status of whether they're an admin and a function to log them out
 */
const ListModal = (props: ListModalProps) => {
  const groundMoistureToContent = (groundMoisture: number | undefined) => {
    return groundMoisture === undefined || groundMoisture === null
      ? "—"
      : Math.round((groundMoisture + Number.EPSILON) * 100) / 100 + "%";
  };

  const convertCategoryToColor = (category: number | undefined) => {
    if (category === undefined) return "normal";
    if (category === 0) return "red";
    if (category === 1) return "yellow";
    return "green";
  };

  const treeHeaders = [
    {content: "Baumnummer", sortable: true, minWidth: "162px"},
    {content: "Baumart", sortable: true, minWidth: "117px"},
    {content: "Bodenart", sortable: true, minWidth: "121px"},
    {content: "VWC", sortable: true, subscript: "30", minWidth: "105px"},
    {content: "VWC", sortable: true, subscript: "100", minWidth: "105px"},
    {content: "nFK", sortable: true, subscript: "30", minWidth: "99px"},
    {content: "nFK", sortable: true, subscript: "100", minWidth: "99px"},
    {content: "Gießen", sortable: false, minWidth: "77px"},
  ];
  if (props.user.isAdmin)
    treeHeaders.push({content: "", sortable: false, minWidth: "101px"});
  const treeEntries = recordToIterable(props.treeData).map((e) => {
    const color = convertCategoryToColor(e.ground_moisture_level?.category);
    const entries: Array<CellData> = [
      {content: e.tree_number},
      {
        content:
          e.species.species_latin + " (" + e.species.species_german + ")",
      },
      {
        content: e.soil_composition ? e.soil_composition.name : "—",
      },
      {
        content: groundMoistureToContent(
          e.ground_moisture_level?.volume_water_content_upper
        ),
      },
      {
        content: groundMoistureToContent(
          e.ground_moisture_level?.volume_water_content_lower
        ),
      },
      {
        content: groundMoistureToContent(
          e.ground_moisture_level?.usable_field_capacity_upper
        ),
        color:
          e.ground_moisture_level === null ||
          e.ground_moisture_level.usable_field_capacity_lower >
            e.ground_moisture_level.usable_field_capacity_upper
            ? undefined
            : color,
      },
      {
        content: groundMoistureToContent(
          e.ground_moisture_level?.usable_field_capacity_lower
        ),
        color:
          e.ground_moisture_level === null ||
          e.ground_moisture_level.usable_field_capacity_upper >
            e.ground_moisture_level.usable_field_capacity_lower
            ? undefined
            : color,
      },
      {
        content: wateringCan,
        useImage: true,
        onClick: () => {
          props.probeWatering(e.id);
        },
      },
    ];
    if (props.user.isAdmin)
      entries.push({
        content: "Bearbeiten",
        onClick: (entryIndex: number) => {
          setEditedTree({tree: e, entryIndex: entryIndex});
        },
      });
    return entries;
  });
  const sensorHeaders = [
    {content: "E-UID", sortable: true, minWidth: "91px"},
    {content: "Sensorname", sortable: true, minWidth: "146px"},
    {content: "gekoppelter Baum", sortable: true, minWidth: "195px"},
  ];
  const sensorEntries = recordToIterable(props.sensor.data).map((s) => {
    return [
      {content: s.e_uid},
      {
        content:
          s.name === null || s.name.length === 0
            ? props.user.isAdmin
              ? ""
              : "—"
            : s.name,
        edit: props.user.isAdmin
          ? {
              setValue: (value: string) => {
                API.editSensor(s.id, {name: value}).then((res) => {
                  if (res.payload) {
                    props.renameSensor(s.id, value);
                  } else if (res.httpCode === 403) props.user.logOut();
                });
              },
              defaultValue: "Hinzufügen",
            }
          : undefined,
      },
      {content: s.tree ? props.treeData[s.tree.id].tree_number : "—"},
    ];
  });
  const wateringHeaders = [
    {content: "Baumnummer", sortable: true, minWidth: "162px"},
    {content: "Rot seit", sortable: true, minWidth: "107px"},
    {content: "nFK", sortable: true, subscript: "30", minWidth: "99px"},
    {content: "nFK", sortable: true, subscript: "100", minWidth: "99px"},
    {content: "Letzte Messung", sortable: true, minWidth: "171px"},
    {
      content: "Ticket – Erstellt am",
      sortable: true,
      minWidth: "195px",
    },
    {
      content: "Ticket – Geschlossen am",
      sortable: true,
      minWidth: "236px",
    },
    {content: "Link", sortable: false, minWidth: "70px"},
    {content: "Status", sortable: true, minWidth: "97px"},
  ];

  const mapTicketStatus = (status: string | null | undefined) => {
    switch (status) {
      case "open":
        return "Offen";
      case "new":
        return "Offen";
      case "closed":
        return "Geschlossen";
      case "failed":
        return "Fehlgeschlagen";
      default:
        return "—";
    }
  };

  const wateringEntries = recordToIterable(props.treeData)
    .filter((e) => e.red_day_count)
    .map((e) => {
      return [
        {content: e.tree_number},
        {content: e.red_day_count + " Tagen"},
        {
          content: groundMoistureToContent(
            e.ground_moisture_level?.usable_field_capacity_upper
          ),
        },
        {
          content: groundMoistureToContent(
            e.ground_moisture_level?.usable_field_capacity_lower
          ),
        },
        {
          content: (
            Number.MAX_SAFE_INTEGER - (e.timestamp_latest_event || 0)
          ).toString(),
          overrideContent: e.timestamp_latest_event
            ? new Date(e.timestamp_latest_event).toLocaleDateString()
            : "—",
        },
        {
          content: (
            Number.MAX_SAFE_INTEGER - (e.ticket?.creation_date || 0)
          ).toString(),
          overrideContent: e.ticket?.creation_date
            ? new Date(e.ticket?.creation_date * 1000).toLocaleDateString()
            : "—",
        },
        {
          content: (
            Number.MAX_SAFE_INTEGER - (e.ticket?.closed_date || 0)
          ).toString(),
          overrideContent: e.ticket?.closed_date
            ? new Date(e.ticket.closed_date * 1000).toLocaleDateString()
            : "—",
        },
        {
          content: e.ticket?.link ? "Ticket" : "—",
          onClick: () => {
            if (e.ticket?.link) window.open(e.ticket?.link, "_blank");
          },
        },
        {content: mapTicketStatus(e.ticket?.status)},
      ];
    });
  const [addingTree, setAddingTree] = useState(false);
  const [editedTree, setEditedTree] = useState<{
    tree: TreeData;
    entryIndex: number;
  } | null>(null);
  const [selectedTab, setSelectedTab] = useState<
    "Tree" | "Sensor" | "Watering"
  >("Tree");
  const selectedTabEntries = () => {
    if (selectedTab === "Tree") return treeEntries;
    if (selectedTab === "Sensor") return sensorEntries;
    return wateringEntries;
  };
  const [sortingStates, setSortingStates] = useState<Array<number>>(
    treeHeaders.map((e) => (e.sortable ? 0 : -1))
  ); // 0 = neutral, 1 = descending, 2 = ascending, -1 = not sortable
  const [sortedEntryIndices, setSortedEntryIndices] = useState(
    treeEntries.map((e, i) => i)
  );

  const getOrderAsIndexMap = (sortIndex: number, descending?: boolean) => {
    const valueMap: Array<{originalIndex: number; value: string}> = [];
    for (const l of selectedTabEntries())
      valueMap.push({
        originalIndex: valueMap.length,
        value: l[sortIndex].content,
      });
    valueMap.sort((a, b) => {
      return (
        (descending ? 1 : -1) *
        a.value.localeCompare(b.value, "de", {numeric: true})
      ); // For DIN 5007-Var. 2: "de-u-co-phonebk"
    });
    return valueMap.map((e) => e.originalIndex);
  };

  const cycleSortingState = (index: number) => {
    if (sortingStates[index] === 2)
      setSortedEntryIndices(selectedTabEntries().map((e, i) => i));
    else if (sortingStates[index] === 1)
      setSortedEntryIndices([...sortedEntryIndices].reverse());
    else setSortedEntryIndices(getOrderAsIndexMap(index));
    setSortingStates(
      sortingStates.map((s, i) => (index === i ? (s + 1) % 3 : 0))
    );
  };

  const recalculateSortingState = () => {
    const index = sortingStates.findIndex((s) => s > 0);
    if (index !== -1)
      setSortedEntryIndices(
        getOrderAsIndexMap(index, sortingStates[index] === 2)
      );
  };

  useEffect(() => {
    recalculateSortingState();
  }, [props.treeData, props.sensor.data]);

  return (
    <>
      {addingTree && props.speciesAutocomplete && props.soilAutocomplete ? (
        <AddTreeModal
          closeModal={props.closeModal}
          goBack={() => {
            setAddingTree(false);
          }}
          speciesData={props.speciesData}
          speciesAutocomplete={props.speciesAutocomplete}
          useEditMode={false}
          addOrEditTree={(t) => {
            setSortedEntryIndices(
              sortedEntryIndices.concat(sortedEntryIndices.length)
            );
            props.addNewTree(t);
          }}
          sensorData={props.sensor.data}
          sensorAutocomplete={props.sensor.autocomplete}
          soilData={props.soilData}
          soilAutocomplete={props.soilAutocomplete}
          user={props.user}
        />
      ) : editedTree && props.speciesAutocomplete && props.soilAutocomplete ? (
        <AddTreeModal
          closeModal={props.closeModal}
          goBack={() => {
            setEditedTree(null);
          }}
          speciesData={props.speciesData}
          speciesAutocomplete={props.speciesAutocomplete}
          useEditMode={true}
          addOrEditTree={props.editTree}
          initialInfo={editedTree}
          deleteTree={(editedTree, entryIndex) => {
            setSortedEntryIndices(
              sortedEntryIndices
                .filter((index) => entryIndex != index)
                .map((i) => (i > entryIndex ? i - 1 : i))
            );
            props.deleteTree(editedTree.id);
          }}
          sensorData={props.sensor.data}
          sensorAutocomplete={props.sensor.autocomplete}
          soilData={props.soilData}
          soilAutocomplete={props.soilAutocomplete}
          user={props.user}
        />
      ) : (
        <div className="listModal">
          <div className="closeButtonContainer">
            <FloatingButton
              id={"addTreeModalCloseButton"}
              image={xCircle}
              onClick={() => props.closeModal()}
            />
          </div>
          <div className="listModalHeader">
            <div className="listModalHeaderTabs">
              <button
                id="listModalShowTreeListButton"
                className={
                  "listModalHeaderTab" +
                  (selectedTab === "Tree" ? " selected" : "")
                }
                onClick={() => {
                  if (selectedTab === "Tree") return;
                  setSortingStates(
                    treeHeaders.map((e) => (e.sortable ? 0 : -1))
                  );
                  setSortedEntryIndices(treeEntries.map((e, i) => i));
                  setSelectedTab("Tree");
                }}
              >
                Bäume
              </button>
              <button
                id="listModalShowSensorListButton"
                className={
                  "listModalHeaderTab" +
                  (selectedTab === "Sensor" ? " selected" : "")
                }
                onClick={() => {
                  if (selectedTab === "Sensor") return;
                  setSortingStates(
                    sensorHeaders.map((e) => (e.sortable ? 0 : -1))
                  );
                  setSortedEntryIndices(sensorEntries.map((e, i) => i));
                  setSelectedTab("Sensor");
                  if (Object.keys(props.sensor.data).length === 0)
                    API.getSensors().then((res) => {
                      if (res.payload) {
                        setSortedEntryIndices(res.payload.map((e, i) => i));
                        props.sensor.setData(res.payload);
                      }
                    });
                }}
              >
                Sensoren
              </button>
              {/* {props.user.isAdmin ? (
                <button
                  id="listModalShowWateringListButton"
                  className={
                    "listModalHeaderTab" +
                    (selectedTab === "Watering" ? " selected" : "")
                  }
                  onClick={() => {
                    if (selectedTab === "Watering") return;
                    setSortingStates(
                      wateringHeaders.map((e) => (e.sortable ? 0 : -1))
                    );
                    setSortedEntryIndices(wateringEntries.map((e, i) => i));
                    setSelectedTab("Watering");
                  }}
                >
                  Gießen
                </button>
              ) : null} */}
            </div>
            {selectedTab === "Tree" && props.user.isAdmin ? (
              <div className="listModalHeaderButtonContainer">
                <LabeledButton
                  id={"listModalAddTreeButton"}
                  label={"Hinzufügen"}
                  importance={"tertiary"}
                  onClick={() => {
                    setAddingTree(true);
                  }}
                  imageAfter={plusCircleInverted}
                />
              </div>
            ) : null}
          </div>
          <div className={"listModalTableContainer"}>
            {selectedTab === "Tree" ? (
              <Table
                idBase={"listModalTree"}
                headers={treeHeaders}
                entries={treeEntries}
                cycleSortingState={cycleSortingState}
                sortingStates={sortingStates}
                sortedEntryIndices={sortedEntryIndices}
              />
            ) : selectedTab === "Sensor" ? (
              <Table
                idBase={"listModalSensor"}
                headers={sensorHeaders}
                entries={sensorEntries}
                cycleSortingState={cycleSortingState}
                sortingStates={sortingStates}
                sortedEntryIndices={sortedEntryIndices}
              />
            ) : wateringEntries.length > 0 ? (
              <Table
                idBase={"listModalWatering"}
                headers={wateringHeaders}
                entries={wateringEntries}
                cycleSortingState={cycleSortingState}
                sortingStates={sortingStates}
                sortedEntryIndices={sortedEntryIndices}
              />
            ) : (
              <div className={"listModalWateringEmptyMessage"}>
                <div>Alle Bäume haben genügend Wasser.</div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export {ListModal};
