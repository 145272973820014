import React from "react";
import "./Footer.css";

/**
 * Displays legally required links in a box at the bottom right of the screen.
 *
 * @component
 */
const Footer = () => {
  return (
    <div className="footerContainer">
      <a
        className="footerContent"
        target="_blank"
        rel="noopener noreferrer"
        href="https://smartandpublic.de/impressum/"
      >
        Impressum
      </a>
      <a
        className="footerContent"
        target="_blank"
        rel="noopener noreferrer"
        href="https://smartandpublic.de/datenschutz/"
      >
        Datenschutz
      </a>
    </div>
  );
};

export {Footer};
