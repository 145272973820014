/**
 * Converts a list of objects to a dictionary.
 *
 * @param keyName Which value of the list elements to use as the dictionary key.
 * @param mapValue If provided, the values in the dictionary will be the return value of this called with a list element.
 */
const objectListToDictionary = <InputElementType, OutputValueType>(
  list: Array<InputElementType>,
  keyName: string,
  mapValue?: (element: InputElementType) => OutputValueType
) => {
  const dict: {[key: string]: OutputValueType} = {};
  for (const e of list) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - I think ensuring that the list only contains elements with the chosen keyName as a property would make the function significantly more cumbersome to use
    if (e[keyName]) dict["" + e[keyName]] = mapValue ? mapValue(e) : e;
    else
      throw new Error(
        "Chosen keyName does not exist on an element in the list."
      );
  }
  return dict;
};

export default objectListToDictionary;
