import React from "react";
import {LabeledButton, labeledButtonProps} from "../basic/LabeledButton";
import "./Popup.css";

type popupProps = {
  title: string;
  text: string;
  options: Array<labeledButtonProps>;
  onBackgroundClick?: () => void;
};

/**
 * A Popup in the center of the screen requiring an action to proceed.
 *
 * @component
 * @props title: A title to display in big at the top of the popup
 * @props text: A description of the action to display in the middle of the popup
 * @props options: An array of buttons to display at the bottom of the popup
 * @props onBackgroundClick?: A function to execute when the area outside the popup is clicked
 */
const Popup = (props: popupProps) => {
  return (
    <div className="popUpBlurOverlay">
      <div className="popupContainer" onClick={(e) => e.stopPropagation()}>
        <div className="popupTitle">{props.title}</div>
        <div className="popupLine" />
        <div className="popupText">{props.text}</div>
        <div className="popupButtonContainer">
          {props.options.map((e, i) => (
            <LabeledButton key={i} {...e} />
          ))}
        </div>
      </div>
    </div>
  );
};

export {Popup, type popupProps};
