import React, {MouseEventHandler} from "react";
import "./LabeledButton.css";

type labeledButtonProps = {
  id: string;
  label: string;
  importance: "primary" | "secondary" | "tertiary";
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  imageBefore?: string;
  imageAfter?: string;
};

/**
 * A button with text.
 *
 * @component
 * @props id: An unique HTML-ID to assign to the button
 * @props label: A text to display on the button
 * @props importance: How prominent the button's styling should be
 * @props onClick: A function to execute when the button is clicked
 * @props disabled?: Whether the button can be clicked
 * @props imageBefore?: The path to an image to display on the button before the label
 * @props imageAfter?: The path to an image to display on the button after the label
 */
const LabeledButton = (props: labeledButtonProps) => {
  return (
    <button
      id={props.id}
      className={"buttonContainer " + props.importance}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.imageBefore ? (
        <img
          className={"buttonIcon " + props.importance}
          src={props.imageBefore}
        />
      ) : null}
      <div className={"buttonText " + props.importance}>{props.label}</div>

      {props.imageAfter ? (
        <img
          className={"buttonIcon " + props.importance}
          src={props.imageAfter}
        />
      ) : null}
    </button>
  );
};

export {LabeledButton, type labeledButtonProps};
