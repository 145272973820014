import React, {useState} from "react";
import "./Table.css";
import sortNeutral from "../../images/sortNeutral.svg";
import sortAscending from "../../images/sortAscending.svg";
import sortDescending from "../../images/sortDescending.svg";

/**
 * @typedef CellData
 * @props content: The text or the path to an image to display
 * @props useImage?: Whether to display an image instead of text
 * @props color?: Which text color to use
 * @props onClick?: A function to execute when the cell (from the row at the affected entryIndex) was clicked
 * @props edit?: Allows editing of the cells content. Displays defaultValue while content is empty and calls setValue with the entered text once the cell looses focus
 * @props overrideContent?: A value to display instead of content (allows customized sorting)
 */
type CellData = {
  content: string;
  useImage?: boolean;
  color?: "normal" | "green" | "yellow" | "red" | "lightBlue" | "darkBlue";
  onClick?: (entryIndex: number) => void;
  edit?: {
    defaultValue: string;
    setValue: (val: string) => void;
  };
  overrideContent?: string;
};

type ListsModalProps = {
  idBase: string;
  headers: Array<{
    content: string;
    sortable: boolean;
    subscript?: string;
    minWidth?: string;
  }>;
  entries: Array<Array<CellData>>;
  sortingStates: Array<number>;
  cycleSortingState: (index: number) => void;
  sortedEntryIndices: Array<number>;
};

/**
 * A Table of text or image entries, with optionally clickable or editable cells.
 *
 * @component
 * @props idBase: A prefix for the HTML-IDs of elements in the table. Should reflect the path of entrypoints
 * @props headers: A list of labels serving as headers for the columns in the table, whether each column is sortable and optionally a subscript to append to the label and how much room should be guaranteed for the column
 * @props entries: A list of the rows in the table – each row is a list of what to display in the cell. Should have the same length as headers
 * @props sortingStates: A sparse list indicating which column to sort by (0 = neutral, 1 = ascending, 2 = descending, -1 = not sortable). Should have the same length as headers
 * @props cycleSortingState: A function to execute when the index-th header is clicked to update the sortingStates
 * @props sortedEntryIndices: A list of indices indicating at which position in the table a row of entries is located, e.g. [5, 0, ...] places entries[5] as the top row, entires[0] as second, .... Should have the same length as entries[x]
 */
const Table = (props: ListsModalProps) => {
  const [editCell, setEditCell] = useState<
    undefined | {row: number; col: number; val: string}
  >();
  const itemToCellContent = (cell: CellData) =>
    cell.useImage ? (
      <img src={cell.content} />
    ) : (
      cell.overrideContent || cell.content
    );
  const itemToClassNames = (cell: CellData, hoverEffect?: boolean) =>
    "tableCellContent" +
    (cell.color ? " " + cell.color + "Color" : "") +
    (cell.useImage ? " imageContainer" : "") +
    (hoverEffect ? " cellHover" : "");

  return (
    <div className="tableScrollContainer">
      <div className="tableContainer">
        {props.headers.map((h, i) => (
          <div
            key={"tableColumn_" + i}
            className={"tableColumn"}
            style={h.minWidth ? {minWidth: h.minWidth} : {}}
          >
            <div key={"tableHeader_" + i} className={"tableHeader"}>
              <div className="tableHeaderLine" />
              {h.sortable ? (
                <button
                  id={"tableHeader_" + i + "_Button"}
                  onClick={() => {
                    props.cycleSortingState(i);
                  }}
                >
                  <div className="tableHeaderContent">
                    <div>
                      {h.content}
                      {h.subscript ? <sub>{h.subscript}</sub> : null}
                    </div>
                    <img
                      className={"tableHeaderSortIcon"}
                      src={
                        [sortNeutral, sortDescending, sortAscending][
                          props.sortingStates[i]
                        ]
                      }
                    />
                  </div>
                </button>
              ) : (
                <div className="tableHeaderContent">{h.content}</div>
              )}
              <div className="tableCellLine" />
            </div>
            {props.sortedEntryIndices.map((e) => (
              <div key={"tableEntry_" + i + "_" + e} className={"tableCell"}>
                {props.entries[e][i].onClick ? (
                  <button
                    id={props.idBase + "TableCell_" + i + "_" + e + "_Button"}
                    className={itemToClassNames(props.entries[e][i])}
                    onClick={() => {
                      props.entries[e][i].onClick?.(e);
                    }}
                  >
                    {itemToCellContent(props.entries[e][i])}
                  </button>
                ) : props.entries[e][i].edit ? (
                  editCell && editCell.row === e && editCell.col === i ? (
                    <input
                      maxLength={32}
                      autoFocus
                      value={editCell ? editCell.val : ""}
                      onChange={(event) => {
                        setEditCell({
                          row: e,
                          col: i,
                          val: event.target.value,
                        });
                      }}
                      className={itemToClassNames(props.entries[e][i])}
                      onBlur={() => {
                        props.entries[e][i].edit?.setValue(editCell.val);
                        setEditCell(undefined);
                      }}
                    />
                  ) : props.entries[e][i].content !== "" ? (
                    <div
                      className={itemToClassNames(props.entries[e][i], true)}
                      onDoubleClick={() => {
                        setEditCell({
                          row: e,
                          col: i,
                          val: props.entries[e][i].content,
                        });
                      }}
                    >
                      {itemToCellContent(props.entries[e][i])}
                    </div>
                  ) : (
                    <button
                      id={props.idBase + "TableCell_" + i + "_" + e + "_Button"}
                      className={itemToClassNames(props.entries[e][i], true)}
                      onClick={() => {
                        setEditCell({row: e, col: i, val: ""});
                      }}
                    >
                      {props.entries[e][i].edit?.defaultValue}
                    </button>
                  )
                ) : (
                  <div className={itemToClassNames(props.entries[e][i])}>
                    {itemToCellContent(props.entries[e][i])}
                  </div>
                )}
                <div className={"tableCellLine"} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export {Table, type CellData};
