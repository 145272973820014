import React, {useEffect, useState} from "react";
import "./NavigationBar.css";
import loggedInUser from "../../images/loggedInUser.svg";
import loggedOutUser from "../../images/loggedOutUser.svg";
import wateringCan from "../../images/wateringCanWhite.svg";
import wateringCanDrag from "../../images/wateringCan.svg";
import nothing from "../../images/nothing.svg";
import {TreeData} from "../../logic/APITypes";
import {Popup} from "../../reusables/compound/Popup";
type navigationTabOptions = "Map" | "List";
type wateringCommandStatus = "Idle" | "Waiting" | "Sent";
type NavigationBarProps = {
  selectedNavigationTab: navigationTabOptions;
  setSelectedNavigationTab: (navigationTabOption: navigationTabOptions) => void;
  isLoggedIn: boolean;
  showLoginWindow: () => void;
  treeData: Record<number, TreeData>;
  probeWatering: (id: number) => void;
  closeModals: () => void;
};

/**
 * Displays the navigation bar at the top of the screen.
 *
 * @component
 * @props selectedNavigationTab: Which of the tabs to display as currently selected – can be implemented as a useState()-pair with setSelectedNavigationTab
 * @props setSelectedNavigationTab: Passes a tab as the new selection on click – can be implemented as a useState()-pair with selectedNavigationTab
 * @props isLoggedIn: Whether the user is logged in changing the user icon
 * @props showLoginWindow: A function that is called when the user wants to log in
 * @props treeData: A list of trees in the system
 * @props probeWatering: A function to call when the user requests watering of a individual tree
 * @props closeModals: A function to call to close any opened modals
 */
const NavigationBar = (props: NavigationBarProps) => {
  const [dragTargets, setDragTargets] = useState<
    Array<{left: number; top: number}>
  >([]);

  const [dragPopupActive, setDragPopupActive] = useState(false);
  const img = new Image();
  img.src = nothing;
  const fakeImg: HTMLImageElement | null = document.getElementById(
    "navFakeDragImage"
  ) as HTMLImageElement;

  const fakeDragImage = (e: MouseEvent) => {
    if (!fakeImg || dragTargets.length === 0) return;
    fakeImg.style.top = e.clientY - 18 + "px";
    fakeImg.style.left = e.clientX - 18 + "px";
  };

  useEffect(() => {
    document.addEventListener("drag", fakeDragImage);
    return () => document.removeEventListener("drag", fakeDragImage);
  });

  return (
    <div className={"navBarContainer"}>
      <div className={"navBarContent"}>
        <button
          id="navigationBarShowListButton"
          className={
            "navBarText" +
            (props.selectedNavigationTab === "List" ? " selected" : "")
          }
          onClick={() => props.setSelectedNavigationTab("List")}
        >
          Liste
        </button>
        <div className={"navBarDivider"}></div>
        <button
          id="navigationBarShowMapButton"
          className={
            "navBarText" +
            (props.selectedNavigationTab == "Map" ? " selected" : "")
          }
          onClick={() => props.setSelectedNavigationTab("Map")}
        >
          Karte
        </button>
        <button
          id="navigationBarDragStart"
          className="navBarWateringImage"
          draggable
          onDragStart={(e) => {
            props.closeModals();
            e.dataTransfer.setDragImage(img, 0, 0);
            e.dataTransfer.setData("text/uri-list", "");
            const markers = document.getElementsByClassName(
              "leaflet-marker-icon"
            );
            const newDragTargets = [];
            for (let i = 0; i < markers.length; i++) {
              const {left, top} = markers[i].getBoundingClientRect();
              newDragTargets.push({left, top});
            }
            setDragTargets(newDragTargets);
          }}
          onDragEnd={() => {
            setDragTargets([]);
            if (!fakeImg) return;
            fakeImg.style.top = "-100px";
            fakeImg.style.left = "-100px";
            fakeImg.classList.remove("targeting");
          }}
          onClick={() => setDragPopupActive(true)}
        >
          <img src={wateringCan} height="36" />
        </button>
        <button
          id="navigationBarLoginButton"
          onClick={() => props.showLoginWindow()}
        >
          <img src={props.isLoggedIn ? loggedInUser : loggedOutUser} />
        </button>
      </div>
      <div className={"navBarBackground"} />
      {dragTargets.map((e, i) => (
        <div
          key={i}
          style={{...e}}
          className="navDragTargets"
          onDragOver={(e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = "link";
          }}
          onDragEnter={() => {
            if (fakeImg) fakeImg.classList.add("targeting");
          }}
          onDragLeave={() => {
            if (fakeImg) fakeImg.classList.remove("targeting");
          }}
          onDrop={(e) => {
            e.preventDefault();
            props.probeWatering(Object.values(props.treeData)[i].id);
          }}
        />
      ))}
      <img id="navFakeDragImage" src={wateringCanDrag} />
      {dragPopupActive ? (
        <Popup
          title={"Gießaufträge erstellen"}
          text={
            "Ziehe die Gießkanne auf einen Baum, um für diesen einen Gießauftrag zu erstellen."
          }
          options={[
            {
              id: "navBarDragInfo",
              label: "Ok",
              importance: "primary",
              onClick: () => setDragPopupActive(false),
            },
          ]}
          onBackgroundClick={() => {
            setDragPopupActive(false);
          }}
        />
      ) : null}
    </div>
  );
};

export {NavigationBar, type navigationTabOptions, type wateringCommandStatus};
